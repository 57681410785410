import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/views/Main.vue'
import Login from '@/views/Login/Login.vue'
import ExceptionPage from '@/views/DefaultPage/ExceptionPage.vue'
import exportCurriculum from '@/views/ExportPage/ExportCurriculum.vue'
// import ResetPassword from '@/views/ResetPassword.vue'
import Store from '@/store/index'
import { i18n } from '@/i18n/i18n'
import { routerRightCode, kindergarten, all, studentStatus } from '@/constant/constant'
import { differSchoolId } from '@/constant/config'

import { StudentController } from '@/services/request.service'

import {
  getToken,
  getSchoolInfo,
  removeToken,
  removeSchoolInfo,
  setSchoolInfo,
  getSchoolList,
  getFirstLogin,
  removeFirstLogin,
  setToken,
  validateVersion,
} from '@/utils/utils'

Vue.use(Router)

const schoolId = (getSchoolInfo() || {}).schoolId

export const studentDetail = {
  path: 'student',
  name: 'studentDetail',
  component: () =>
    import(/* webpackChunkName: "studentDetail" */ '@/views/StudentDetail/StudentDetail.vue'),
  meta: {
    routerName: 'studentDetail',
    rootPage: true,
    noChild: true,
  },
}

export const homework = {
  path: 'homework/:classType',
  name: 'homework',
  component: () => import(/* webpackChunkName: "homework" */ '@/views/HomeWork/HomeWork.vue'),
  meta: {
    routerName: 'homework',
    rootPage: true,
    noChild: true,
    showDot: true,
  },
  children: [
    {
      path: 'homeworkDetail',
      name: 'homeworkDetail',
      component: () =>
        import(/* webpackChunkName: "homework" */ '@/views/HomeWork/HomeWorkDetail.vue'),
      meta: {
        routerName: 'homeworkDetail',
        rootPage: true,
      },
    },
  ],
}

export const diary = {
  path: 'diary',
  name: 'diary',
  component: () => import(/* webpackChunkName: "diary" */ '@/views/Diary/Diary.vue'),
  meta: {
    routerName: 'diary',
    rootPage: true,
    noChild: true,
  },
}

export const moment = {
  path: 'moment',
  name: 'moment',
  component: () => import(/* webpackChunkName: "moment" */ '@/views/Moment/Moment.vue'),
  meta: {
    routerName: 'moment',
    rootPage: true,
    noChild: true,
  },
}

export const points = {
  path: 'points',
  name: 'points',
  component: () => import(/* webpackChunkName: "points" */ '@/views/Points/Points.vue'),
  meta: {
    routerName: 'points',
    rootPage: true,
    noChild: true,
  },
}

export const attendance = {
  path: 'attendance',
  name: 'attendance',
  component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/Attendance.vue'),
  meta: {
    routerName: 'attendance',
    noChild: true,
  },
}

export const calendar = {
  path: 'calendar',
  name: 'calendar',
  component: () => import(/* webpackChunkName: "calendar" */ '@/views/Calendar/Calendar.vue'),
  meta: {
    routerName: 'calendar',
    noChild: true,
  },
}

export const message = {
  path: 'message',
  name: 'message',
  component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Message.vue'),
  redirect: { name: 'outbox' },
  meta: {
    routerName: 'message',
  },
  children: [
    {
      path: 'outbox',
      name: 'outbox',
      component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Outbox.vue'),
      meta: {
        routerName: 'outbox',
        rootPage: true,
        noChild: true,
      },
      children: [
        {
          path: 'send',
          name: 'sendMessage',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Send.vue'),
          meta: {
            routerName: 'sendMessage',
          },
        },
        {
          path: 'detail/:messageId',
          name: 'outDetail',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Detail.vue'),
          meta: {
            routerName: 'sentDetail',
          },
        },
      ],
    },
    {
      path: 'inbox',
      name: 'inbox',
      component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Inbox.vue'),
      meta: {
        routerName: 'inbox',
        rootPage: true,
      },
      children: [
        {
          path: 'reply',
          name: 'replyMessage',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Send.vue'),
          meta: {
            routerName: 'replyMessage',
          },
        },
        {
          path: 'detail/:messageId',
          name: 'inDetail',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Detail.vue'),
          meta: {
            routerName: 'receivedDetail',
          },
        },
      ],
    },
  ],
}

export const addressBook = {
  path: 'addressBook',
  name: 'addressBook',
  component: () => import(/* webpackChunkName: "otherApp" */ '@/views/AddressBook/AddressBook.vue'),
  redirect: { name: 'schoolAddressBook' },
  meta: {
    routerName: 'addressBook',
    // noChild: true,
    // rootPage: true,
  },
  children: [
    {
      path: 'schoolAddressBook',
      name: 'schoolAddressBook',
      component: () =>
        import(/* webpackChunkName: "otherApp" */ '@/views/AddressBook/SchoolAddressBook.vue'),
      meta: {
        routerName: 'schoolAddressBook',
        rootPage: true,
        // showStudents: true,
        noChild: true,
      },
    },
    {
      path: 'privateAddressBook',
      name: 'privateAddressBook',
      component: () =>
        import(/* webpackChunkName: "otherApp" */ '@/views/AddressBook/PrivateAddressBook.vue'),
      meta: {
        routerName: 'privateAddressBook',
        rootPage: true,
        showStudents: true,
        noChild: true,
      },
    },
  ],
}

const teaching = {
  path: 'teaching',
  name: 'teaching',
  component: () => import(/* webpackChunkName: "assignment" */ '@/views/Teaching/Teaching.vue'),
  meta: {
    routerName: 'teaching',
    rootPage: true,
    noChild: true,
  },
  children: [
    {
      path: 'assignmentDetail/:id',
      name: 'assignmentDetail',
      component: () =>
        import(
          /* webpackChunkName: "assignment" */ '@/views/Teaching/components/AssignmentDetail.vue'
        ),
      meta: {
        routerName: 'assignmentDetail',
      },
    },
  ],
}

export const CCAClass = {
  path: 'CCAClass',
  name: 'CCAClass',
  component: () =>
    import(/* webpackChunkName: "courseArranging" */ '@/views/CCAClass/CCAClass.vue'),
  meta: {
    routerName: 'CCAClass',
    rootPage: true,
    noChild: true,
  },
}

export const courseSelection = {
  path: 'courseSelection',
  name: 'courseSelection',
  component: () =>
    import(/* webpackChunkName: "courseArranging" */ '@/views/CourseSelection/CourseSelection.vue'),
  meta: {
    routerName: 'courseSelection',
    rootPage: true,
    noChild: true,
  },
}

export const personal = {
  path: 'personal',
  name: 'personal',
  component: () => import(/* webpackChunkName: "personal" */ '@/views/Personal/Personal.vue'),
  hidden: true,
  meta: {
    routerName: 'personal',
    rootPage: true,
    noChild: true,
  },
}

export const systemMessage = {
  path: 'systemMessage',
  name: 'systemMessage',
  component: () =>
    import(/* webpackChunkName: "systemMessage" */ '@/views/SystemMessage/SystemMessage.vue'),
  hidden: true,
  meta: {
    routerName: 'systemMessage',
    rootPage: true,
    noChild: true,
  },
}

export const announcement = {
  path: 'announcement',
  name: 'announcement',
  component: () =>
    import(/* webpackChunkName: "announcement" */ '@/views/Announcement/Announcement.vue'),
  hidden: true,
  meta: {
    routerName: 'announcement',
    rootPage: true,
    showStudents: true,
    noChild: true,
  },
  children: [
    {
      path: 'announcementDetail/:id',
      name: 'announcementDetail',
      component: () =>
        import(
          /* webpackChunkName: "announcement" */ '@/views/Announcement/components/AnnouncementDetail.vue'
        ),
      meta: {
        routerName: 'announcementDetail',
        showHeader: false,
      },
    },
  ],
}

export const curriculum = {
  path: 'curriculum',
  name: 'curriculum',
  component: () => import(/* webpackChunkName: "curriculum" */ '@/views/Curriculum/Curriculum.vue'),
  hidden: true,
  meta: {
    routerName: 'curriculum',
    rootPage: true,
    showStudents: true,
    noChild: true,
  },
}

export const report = {
  path: 'report',
  name: 'report',
  component: () => import(/* webpackChunkName: "report" */ '@/views/Report/Report.vue'),
  hidden: true,
  meta: {
    routerName: 'report',
    rootPage: true,
    showStudents: true,
    noChild: true,
  },
}

const defaultPage = [
  {
    path: '/404',
    name: '404',
    component: ExceptionPage,
    props: {
      type: '404',
    },
  },
  {
    path: '/403',
    name: '403',
    component: ExceptionPage,
    props: {
      type: '403',
    },
  },
]

const exportPage = [
  {
    path: '/exportCurriculum/:token/:schoolId/:time/:key',
    name: 'exportCurriculum',
    component: exportCurriculum,
  },
  {
    path: '/exportReport/:token/:schoolId/:periodId/:reportType/:campusType/:time/:schoolYearType',
    name: 'exportReport',
    component: () =>
      import(/* webpackChunkName: "exportPage" */ '@/views/ExportPage/ExportReport.vue'),
  },
  {
    path: '/exportMonthlyReport/:token/:schoolId/:studentId/:periodId',
    name: 'exportMonthlyReport',
    component: () =>
      import(/* webpackChunkName: "exportPage" */ '@/views/ExportPage/ExportMonthlyReport.vue'),
  },
  {
    path: '/teacherLogin/:token/:schoolId',
    name: 'teacherLogin',
    component: () =>
      import(/* webpackChunkName: "teacherLogin" */ '@/views/TeacherLogin/TeacherLogin.vue'),
  },
]

const routes = [
  {
    path: '/',
    redirect: to => {
      const auths = Store.state.routeAuths
      return calcRedirectRoute(auths)
    },
    name: 'main',
    component: Main,
    children: [
      announcement,
      studentDetail,
      curriculum,
      (!differSchoolId.includes(schoolId) && homework) as any,
      report,
      // courseMaterial,
      points,
      // moment,
      attendance,
      diary,
      message,
      addressBook,
      CCAClass,
      calendar,
      personal,
      systemMessage,
      teaching,
      courseSelection,
    ].filter(item => item),
  },
]

function calcRedirectRoute(auths): any {
  let name
  for (let i = 0; i < auths.length; i++) {
    if (auths[i]) {
      name = auths[i]
      break
    }
  }
  if (!name) {
    name = '403'
  }
  return { name }
}
Store.commit('setRoutes', routes)

const constantRouter = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  ...defaultPage,
  ...exportPage,
  ...routes,
]

const router = new Router({
  mode: 'history',
  routes: constantRouter,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  },
}) as any

function syncRoute(): Promise<any> {
  let routeAuth = {} as any
  return new Promise(resolve => {
    StudentController.getUserInfo()
      .then(res => {
        let student = (res.data || {}) as any
        let allFlag = student.campusType !== '1230'

        let allAuthCode = [...new Set(allFlag ? all : kindergarten)].filter(item =>
          studentStatus[student.status].includes(item)
        )

        routeAuth = allAuthCode
          .map(item => {
            if (typeof routerRightCode[item] !== 'object') return routerRightCode[item]
            const { name, children } = routerRightCode[item]
            return [name].concat(children || [])
          })
          .flat()
          .filter(item => item)
        Store.commit('setRouteAuths', routeAuth)
        Store.commit('setAuthLoadStatus', true)
        Store.commit('setUserInfo', {
          username: (res.data.enName + ' ' + res.data.name).trim(),
          memberId: res.data.studentId,
          ...res.data,
        })
        resolve('success')
      })
      .catch(err => {
        Store.commit('setAuthLoadStatus', true)
        resolve('failed')
      })
  })
}

router.beforeEach(async (to, from, next) => {
  Store.commit('changeForceBack', false)
  if (exportList.includes(to.name)) {
    if (!to.params.token) {
      next({ name: 'login' })
    } else {
      setToken(to.params.token as string, false)
      setSchoolInfo({ schoolId: to.params.schoolId })
      next()
    }
    return
  }

  let routeAuth = Store.state.routeAuths
  let authLoaded = Store.state.authLoaded
  const schoolList = getSchoolList()
  let schoolInfo: any
  if (schoolList && schoolList.length) {
    const host = window.location.host
    schoolInfo = getSchoolList().filter(item => item.domain === host)[0]
    if (!schoolInfo && process.env.VUE_APP_ENV === 'DEV') {
      schoolInfo = getSchoolList().find(item => item.schoolId === 2)
    }
    setSchoolInfo(schoolInfo || {})
  }
  if (to.name === '404') {
    next()
  } else if (!schoolInfo) {
    next({ name: '404', query: { message: i18n.tc('tips.nonExistSchool') } })
  } else if (to.name === 'login') {
    if (getToken() && !getFirstLogin()) {
      next({ name: 'main' })
    } else {
      removeToken()
      removeFirstLogin()
      next()
    }
  } else if (!getToken()) {
    next({ name: 'login', query: { toPath: to.fullPath } })
  } else if (!authLoaded) {
    await syncRoute()
    let el = document.getElementById('project-loading') as any
    if (el) el.remove()
    next(
      to.name === '403'
        ? { name: 'main' }
        : {
            ...to,
          }
    )
  } else if (whiteList.includes(to.name) || routeAuth.includes(to.name)) {
    if (!getFirstLogin()) {
      validateVersion(false, () => {
        next()
      })
    } else {
      next({ name: 'login' })
    }
  } else if (to.matched.length === 0) {
    next({ name: '404' })
  } else {
    // 没有权限
    next({ name: '403' })
  }
})

const exportList = ['exportCurriculum', 'exportReport', 'teacherLogin', 'exportMonthlyReport']

export const whiteList = ['personal', 'systemMessage', 'login', '404', '403']

export default router

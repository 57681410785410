/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
import Vue from 'vue'
import * as AntDes from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import '@/utils/filter'
import { i18n } from '@/i18n/i18n'
import SvgIcon from '@/components/SvgIcon.vue'
Vue.component('SvgIcon', SvgIcon)

import { getLocale, setLocale, getSchoolList, setSchoolList, aDownload } from '@/utils/utils'
import { LoginController } from '@/services/request.service'
import VueLazyLoad from 'vue-lazyload'
import moment from 'moment-timezone'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import config from '../package.json'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import baiduAnalytics from 'vue-baidu-analytics'

import xss, { escapeAttrValue } from 'xss'
Vue.prototype.xss = html =>
  xss(html, {
    onIgnoreTagAttr: function(tag, name, value, isWhiteAttr) {
      if (name === 'style') {
        // 通过内置的escapeAttrValue函数来对属性值进行转义
        return name + '="' + escapeAttrValue(value) + '"'
      }
    },
  })

import 'simditor/styles/simditor.css'
import '@/components/simditor/style.less'

moment.tz.setDefault('Asia/Shanghai')

Vue.use(AntDes)

Vue.use(VueLazyLoad, {
  loading: require('@/assets/images/loading.gif'),
})

Vue.prototype.$info = AntDes.Modal.info
Vue.prototype.$error = AntDes.Modal.error
Vue.prototype.$confirm = AntDes.Modal.confirm
Vue.prototype.$message = AntDes.message
Vue.prototype.$aDownload = aDownload
Vue.prototype.$document = document
Vue.prototype.$notification = AntDes.notification

Vue.config.productionTip = false

declare module 'vue/types/vue' {
  interface Vue {
    $document: typeof document
  }
}

let locale = getLocale()
if (!locale) {
  const systemLanguage = navigator.language === 'en' ? 'en' : 'zh'
  setLocale(systemLanguage)
  i18n.locale = systemLanguage
  store.commit('changeLocale', systemLanguage)
} else {
  i18n.locale = locale
  store.commit('changeLocale', locale)
}

Vue.use(Viewer, {
  defaultOptions: {
    title: false,
  },
})

Vue.use(baiduAnalytics, {
  router: router,
  siteIdList: [],
  isDebug: false,
})

if (!getSchoolList() || !getSchoolList().length) {
  LoginController.getAllSchools()
    .then(res => {
      setSchoolList(res ? res.data : [])
      new Vue({
        router,
        store,
        i18n,
        render: h => h(App),
      }).$mount('#app')
    })
    .catch(err => {
      console.error(err)
      // window.location.reload()
    })
} else {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}

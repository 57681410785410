/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosPromise } from 'axios'
import { message } from 'ant-design-vue'
import { getToken, getSchoolInfo, removeToken, removeSchoolInfo, getLocale } from './utils'
import router from '@/router'
import qs from 'qs'

const Axios = axios.create({
  timeout: 30000,
  baseURL: process.env.VUE_APP_REQUEST_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
})

let pending: string[] = [] // 定义(防重复提交)
const CancelToken = axios.CancelToken
const removePending = (config: AxiosRequestConfig, cancelToken: any) => {
  const url: string = config.url + '&' + config.method
  const postList = ['/common/saveResource&post']
  if (pending.indexOf(url) >= 0 && !postList.includes(url)) {
    if (cancelToken) {
      cancelToken(url + '：request execution....') // 执行取消操作
    } else {
      // 从数组中移除
      setTimeout(() => {
        pending.splice(pending.indexOf(url), 1)
      }, 100)
    }
  } else {
    if (cancelToken) {
      pending.push(url)
    }
  }
}

// 请求前拦截器
Axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.paramsSerializer = params => {
      return qs.stringify(params, { allowDots: true, arrayFormat: 'repeat' })
    }
    if (config.method === 'post') {
      config.cancelToken = new CancelToken(c => {
        removePending(config, c)
      })
    }
    // 请求前添加token
    const token = getToken()
    const schoolInfo = getSchoolInfo()
    config.headers['X-Token'] = token
    config.headers['X-Locale'] = getLocale()
    if (schoolInfo && schoolInfo.schoolId) {
      config.headers['X-SchoolId'] = schoolInfo.schoolId
    }

    return config
  },
  (error: any) => Promise.reject(error)
)

// 请求后拦截器
Axios.interceptors.response.use(
  (response: AxiosResponse) => {
    const oldVersion = localStorage.getItem('version')
    const newVersion = response.headers['application-version']
    // if (newVersion !== oldVersion) {
    // localStorage.setItem('version', newVersion)
    // top.frames.location.reload(true)
    // }
    if (response.config.method === 'post') {
      removePending(response.config, null)
    }
    return response
  },
  (error: any) => {
    pending = []
    if (!error.response && !error.message) {
      message.error('Network Error 网络错误')
      return Promise.reject(error)
    }
    if (error.response) {
      if (error.response.status === 400) {
        const result = error.response.data
        // 自定义异常
        if (result.status !== 200) {
          if (result.status === 401) {
            removeToken()
            removeSchoolInfo()
            router.push({ name: 'login' }).catch(err => {})
            return Promise.reject(error)
          } else if (result.status === 1011) {
            message.error(result.error)
            removeToken()
            removeSchoolInfo()
            router.push({ name: 'login' }).catch(err => {})
            return Promise.reject(error)
          } else {
            message.error(result.error)
            return Promise.reject(error)
          }
        }
      } else if (error.response.status === 401) {
        message.error('401: Unauthorized 未授权', 3, () => {
          removeToken()
          removeSchoolInfo()
          router.push({ name: 'login' }).catch(err => {})
        })
        return Promise.reject(error)
      } else if (error.response.status === 404) {
        message.error('404: Not Found 地址不存在')
        return Promise.reject(error)
      } else {
        message.error('500: Server Exception 服务器异常')
        return Promise.reject(error)
      }
    }
    // reject
    message.error(error.message)
    return Promise.reject(error)
  }
)

// 每5s清空pending
setInterval(() => {
  pending = []
}, 5000)

declare module 'axios' {
  export interface AxiosInstance {
    delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>
    head<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>
  }
}

export default Axios

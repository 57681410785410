




















































































































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator'
import { LoginController } from '@/services/request.service'
const operations = {
  forgetPassword: function(condition) {
    return LoginController.resetPassword(condition)
  },
}
@Component
export default class SetPasswordModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly type!: any
  @Prop() private readonly data!: any

  private setPwdForm: any
  private verCodeTimer = 0
  private resetTimer: any = null
  private verCodeLoading = false
  private resetLoading = false
  private resetMailTips = ''

  private created(): void {
    this.setPwdForm = this.$form.createForm(this)
  }

  private validateResetPwd(rule, value, cb) {
    const form = this.setPwdForm
    const exp = /^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/
    if (exp.test(value)) {
      if (value) {
        form.validateFields(['repeatPassword'], { force: true })
      }
    } else {
      cb(this.$t('personal.passwordUnLaw'))
    }
    cb()
  }

  private getResetVerCode(): void {
    const form = this.setPwdForm
    const studentId = form.getFieldValue('studentId')
    if (studentId) {
      this.verCodeLoading = true
      LoginController.sendVerifyCode(studentId)
        .then(res => {
          if (res.data.success) {
            this.$message.success(this.$tc('common.sendVerCodeSuccess'))
            this.verCodeTimer = 60
            this.resetMailTips = res.data.resMsg
            this.resetResendCountDown()
          } else {
            const studentId = form.getFieldValue('studentId')
            form.setFields({
              ['studentId']: {
                value: studentId,
                errors: [{ message: res.data.resMsg, field: 'mobile' }],
              },
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (this.verCodeLoading = false))
      // LoginController.sendVerifyCode(studentId)
      //   .then(res => {
      //     if (res.data.success) {
      //       this.$message.success(this.$tc('common.sendVerCodeSuccess'))
      //       this.verCodeTimer = 60
      //       this.resetResendCountDown()
      //       form.setFields({
      //         ['email']: {
      //           value: studentId,
      //         },
      //       })
      //     } else {
      //       form.setFields({
      //         ['email']: {
      //           value: email,
      //           errors: [{ message: res.data.resMsg, field: 'email' }],
      //         },
      //       })
      //     }
      //   })
      //   .catch(err => {
      //     console.error(err)
      //   })
      //   .finally(()=> this.verCodeLoading=false)
    } else {
      form.setFields({
        ['studentId']: {
          value: studentId,
          errors: [{ message: this.$t('login.enterStudentId'), field: 'studentId' }],
        },
      })
    }
  }

  private resetResendCountDown(): void {
    this.resetTimer = setInterval(() => {
      if (this.verCodeTimer === 0) {
        clearInterval(this.resetTimer)
        this.resetTimer = null
      } else {
        this.verCodeTimer = this.verCodeTimer - 1
      }
    }, 1000)
  }

  private validateResetRepeatPwd(rule, value, callback) {
    const form = this.setPwdForm
    if (value && value !== form.getFieldValue('password')) {
      callback(this.$t('login.inconsistentPwd'))
    } else {
      callback()
    }
  }

  private finish(): void {
    this.resetLoading = true
    const form = this.setPwdForm
    form.validateFields((err: any, values: any) => {
      if (!err) {
        const { password, repeatPassword, ...data } = values
        let condition = {
          ...data,
          newPassword: this.type === 'setPassword' ? undefined : password,
          password: this.type === 'setPassword' ? password : undefined,
          studentNum: data.studentId,
        }
        operations[this.type](condition)
          .then(res => {
            if (res.data.success) {
              this.$message.success(this.$tc('common.setPasswordSuccess'))
              this.$emit('success', res.data)
            } else {
              const studentId = form.getFieldValue('studentId')
              form.setFields({
                ['studentId']: {
                  value: studentId,
                  errors: [
                    { message: this.$tc(`errorCode.${res.data.resCode}`), field: 'studentId' },
                  ],
                },
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.resetLoading = false
          })
      } else {
        this.resetLoading = false
        return
      }
    })
  }

  @Emit('change')
  private closeModal(): boolean {
    this.resetMailTips = ''
    this.verCodeLoading = false
    this.verCodeTimer = 0
    clearTimeout(this.resetTimer)
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"set-mail-modal",attrs:{"visible":_vm.visible,"destroyOnClose":"","centered":"","closable":false,"keyboard":false,"footer":null,"maskClosable":false,"width":400},on:{"cancel":_vm.closeModal}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('login.improveInfo'))+" ")]),_c('a-form',{attrs:{"form":_vm.mailForm,"autocomplete":"nope"}},[_c('a-form-item',{attrs:{"label":_vm.$t('common.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('login.enterMail'),
              } ],
          } ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('login.enterMail'),\n              },\n            ],\n          },\n        ]"}],staticClass:"login-input",attrs:{"size":"large","placeholder":_vm.$t('login.enterMail')}})],1),_c('a-form-item',{staticClass:"special-form-item-20",staticStyle:{"line-height":"20px"}},[_c('span',[_vm._v(_vm._s(_vm.$t('login.improveTips')))])]),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large","loading":_vm.confirmLoading},on:{"click":_vm.confirm}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
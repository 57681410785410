export const differSchoolId = [2, 5, 7, 8, 9]
export const staticData = {
  helpDocUrl: 'https://www.yuque.com/docs/share/f4d48bdb-4b4f-41ce-a198-79ce5ad0f453?#',
  feedbackMail: 'intschoolsupport@huaercollegiate.com',
}

export default {
  differSchoolId,
  staticData,
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"set-password-modal",attrs:{"visible":_vm.visible,"title":_vm.$tc(("login." + _vm.type)),"destroyOnClose":"","centered":"","footer":null,"maskClosable":false,"width":600},on:{"cancel":_vm.closeModal}},[_c('a-form',{attrs:{"form":_vm.setPwdForm,"autocomplete":"nope"}},[_c('a-form-item',[(_vm.resetMailTips)?_c('div',{staticClass:"primary-font",attrs:{"slot":"extra"},slot:"extra"},[_vm._v(" "+_vm._s(_vm.$t('login.mailTips', { tips: _vm.resetMailTips }))+" ")]):_vm._e(),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'studentId',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('login.enterStudentId'),
              } ],
          } ]),expression:"[\n          'studentId',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('login.enterStudentId'),\n              },\n            ],\n          },\n        ]"}],staticClass:"login-input",attrs:{"size":"large","placeholder":_vm.$t('login.enterStudentId')}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'vcode',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('login.enterVerCode'),
              } ],
          } ]),expression:"[\n          'vcode',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('login.enterVerCode'),\n              },\n            ],\n          },\n        ]"}],staticClass:"login-input",staticStyle:{"width":"55%"},attrs:{"size":"large","placeholder":_vm.$t('login.verCode')}}),_c('a-button',{staticStyle:{"width":"40%","margin-left":"5%"},attrs:{"size":"large","loading":_vm.verCodeLoading,"disabled":_vm.verCodeTimer > 0},on:{"click":_vm.getResetVerCode}},[_vm._v(_vm._s(_vm.verCodeTimer ? _vm.$t('login.resendVerCode', _vm.verCodeTimer, { time: _vm.verCodeTimer }) : _vm.$t('login.getVerCode')))])],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              {
                validator: _vm.validateResetPwd,
              } ],
          } ]),expression:"[\n          'password',\n          {\n            rules: [\n              {\n                validator: validateResetPwd,\n              },\n            ],\n          },\n        ]"}],staticClass:"login-input",attrs:{"size":"large","placeholder":_vm.$t('login.resetPwdTip')}})],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'repeatPassword',
          {
            rules: [
              {
                validator: _vm.validateResetRepeatPwd,
              } ],
          } ]),expression:"[\n          'repeatPassword',\n          {\n            rules: [\n              {\n                validator: validateResetRepeatPwd,\n              },\n            ],\n          },\n        ]"}],staticClass:"login-input",attrs:{"size":"large","placeholder":_vm.$t('login.confirmPwd')}})],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large","loading":_vm.resetLoading},on:{"click":_vm.finish}},[_vm._v(_vm._s(_vm.$t('common.finish')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
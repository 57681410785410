export default {
  platformName: 'Student Platform',
  changeLocale: '中文',
  staticCode: {
    '1001': 'Unopen',
    '1011': 'Weekly',
    '1012': 'Biweekly',
    '1013': 'Once',
    '1021': 'Enrollable',
    '1022': 'Enrolled',
    '1023': 'rejected',
    '1024': 'Enroll Success',
    '1025': 'Enroll Failed',
    '1026': 'Full',
    '1201': 'Unopen',
    '1202': 'Processing',
    '1203': 'Finished',
    '1204': 'Semester1',
    '1205': 'Semester2',
    '1208': 'Not Open',
    '1209': 'Ongoing',
    '1210': 'Graduated',
  },
  relation: {
    father: 'Father',
    mother: 'Mother',
    patGrandPa: 'Paternal Grandfather',
    patGrandMa: 'Paternal Grandmother',
    matGrandPa: 'Maternal Grandfather',
    matGrandMa: 'Maternal Grandmother',
    other: 'Other',
  },
  router: {
    homeworkDetail: 'Task Detail',
    points: 'Score',
    studentDetail: 'Student Detail',
    homework: 'Task',
    diary: 'Student Log',
    addDiary: 'Add Student Log',
    editDiary: 'Edit Student Log',
    performance: 'Performance',
    attendance: 'Attendance',
    calendar: 'Calendar',
    message: 'Message',
    inbox: 'Inbox',
    outbox: 'Outbox',
    sendMessage: 'Send Message',
    sentDetail: 'Detail',
    replyMessage: 'Reply Message',
    receivedDetail: 'Detail',
    personal: 'Personal',
    systemMessage: 'System Message',
    CCAClass: 'Life Block',
    CCAClassDetail: 'Life Block Detail',
    homeworkViewer: 'Task List',
    homeworkViewerChild: {
      CCAClass: 'Life Block Task',
      courseClass: 'Course Class Task',
    },
    courseMaterial: 'Class Resource',
    moment: 'Moment',
    assignment: 'Assignment',
    assignmentDetail: 'Assignment Detail',
    teaching: 'Teaching',
    courseSelection: 'Course Selection',
    addressBook: 'Address Book',
    schoolAddressBook: 'Faculty Directory',
    privateAddressBook: 'Private Address Book',
    announcement: 'Announcement',
    announcementDetail: 'Announcement Detail',
    curriculum: 'Timetable',
    report: 'Report',
  },
  login: {
    userLogin: 'User Login',
    account: 'Student ID or Email',
    password: 'Password',
    enterAccount: 'Please enter student id or email',
    enterPassword: 'Please enter password',
    loginAuto: 'Automatic login within 7 days',
    login: 'Login',
    forgetPassword: 'Forget Password',
    mobile: 'Mobile',
    verCode: 'Ver-code',
    enterVerCode: 'Please enter ver-code',
    enterMobile: 'Please enter mobile',
    getVerCode: 'Get ver-code',
    resendVerCode: 'Resend after {time}s',
    resetPwdTip: 'The password is 8-20 characters and must contain numbers and letters',
    confirmPwd: 'Confirm password',
    inconsistentPwd: 'Inconsistent Password',
    invalidPwd: 'Invalid Password',
    setPassword: 'Set Login Password',
    enter: 'Enter',
    firstLogin: 'First Login',
    enterMail: 'Please Enter Email',
    improveInfo: 'Improve Email Info',
    improveTips: 'You can login by using email after improving your email info',
    tips:
      'It is recommended to use Google Chrome and the latest version of Edge. Compatible with Safari. It is not recommended to use computers with a resolution lower than 1366*768 and mobile phones to view. Not compatible with IE browser.',
    mailTips: 'VerCode has been sended to {tips}',
    enterStudentId: 'Please enter student id',
  },
  common: {
    integral: 'Integral',
    unsaveConfirm: 'Are you sure leave before content has been saved?',
    typeError: 'Type Error',
    textLimit11: 'Limit to 11 characters',
    submit: 'Submit',
    clear: 'Clear',
    seq: 'Sequence',
    operations: 'Operations',
    total: 'Total: {total} records',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    reset: 'Reset',
    resetPwd: 'Reset Password',
    enter: 'Please enter',
    select: 'Please select',
    confirm: 'Confirm',
    cancel: 'Cancel',
    true: 'Yes',
    false: 'No',
    gender: { male: 'Male', female: 'Female' },
    saveSuccess: 'Save Successfully',
    deleteSuccess: 'Delete Successfully',
    resetSuccess: 'Reset Successfully',
    save: 'Save',
    all: 'All',
    submitSuccess: 'Submit Successfully',
    terminateSuccess: 'Terminate Successfully',
    adjustSuccess: 'Adjust Successfully',
    attendanceSuccess: 'Set Attendance Successfully',
    view: 'View',
    enterContent: 'Please enter content',
    sendVerCodeSuccess: 'Send Ver-code Successfully',
    setPasswordSuccess: 'Set Password Successfully',
    send: 'Send',
    sendSuccess: 'Send Successfully',
    download: 'Export',
    export: 'Export',
    success: 'Success',
    downloadResource: 'Download Class Resource',
    pageTotal: 'Total {total} items',
    date: 'Date',
    loadMore: 'Load More...',
    noMore: 'No More Data',
    refreshPage: 'Refresh',
    finish: 'Finish',
    email: 'Email',
    subjectClass: 'Subject Class',
    name: 'Name',
    deadline: 'Deadline',
    type: 'Type',
    subject: 'Subject Category',
    description: 'Description',
    status: 'Status',
    content: 'Content',
    attachment: 'Attachment',
    term: 'Term',
    schoolYear: 'School Year',
    backToHome: 'Back To Home',
    logout: 'Logout',
    score: 'Score',
    grade: 'Grade',
    createTime: 'Create Time',
    CCAClass: 'Life Block',
    creator: 'Creator',
    weekday: 'Weekday',
    time: 'Time',
    teacher: 'Teacher | Teachers',
    fold: 'Fold',
    unfold: 'Unfold',
    classroom: 'Classroom',
    deleteConfirm: 'Are you sure delete?',
    copySuccess: 'Copy Successfully',
    copy: 'copy',
    selected: 'Selected {total} records',
    readAll: 'Read All',
    readSuccess: 'Set to Read Successfully',
  },
  main: {
    quit: 'Quit',
    helpDoc: 'Help Document',
    feedback: 'Feedback',
    feedbackTips:
      'If you encounter technical problems during use, please send an email to the school mailbox:',
    introduction: 'IntSchool Introduction',
    guide: 'Newbie Guide',
    moreDetails: 'More Details',
  },
  intros: {
    studentDetail:
      'Detailed electronic documents (class schedule, semester report, teacher information)',
    teaching: 'Easy to understand the assignment, check the course resource at any time',
    diary: `Students' all-round growth record, such as rewards and punishment, life and health.`,
    moment: 'Class, campus dynamics can be seen',
    attendance: 'Real-time record of school attendance status',
    message: 'Teachers and students communicate more smoothly, email exchange',
    calendar: 'Calendar information, schedule more clear',
    lifeBlock: 'Life Block elective course registration is easy and quick',
  },
  student: {
    parent: 'Parent',
    studentInfo: 'Student',
    parentInfo: 'Parent',
    curriculumInfo: 'Timetable',
    teacherInfo: 'Teacher',
    classInfo: 'Class',
    subjectInfo: 'Course',
    attendanceInfo: 'Attendance',
    instruction: 'Instruction',
    chineseName: 'Name (Chinese)',
    englishName: 'Name (English)',
    firstName: 'First Name (pinyin)',
    lastName: 'Last Name (pinyin)',
    gender: 'Gender',
    birthday: 'Birthday',
    idType: 'ID Type',
    idNumber: 'ID Number',
    nationality: 'Nationality(Subject to passport)',
    email: 'Email',
    residentialAddress: 'Residential Address',
    address: 'Address',
    section: 'Grade',
    class: 'Class',
    studentId: 'Student ID',
    house: 'Advisory',
    startYear: 'Start Year',
    departureTime: 'Departure Time',
    schoolBus: 'School Bus',
    route: 'Route',
    placeToMeet: 'Place to Meet',
    accommodation: 'Accommodation',
    platformLoginRight: 'Platform Login',
    loginAccount: 'Login Account',
    password: 'Password',
    resetPassword: 'Reset Password',
    siblingsInfo: 'Sibling',
    childName: 'Child Name',
    firstParent: 'Main Contact',
    secondParent: 'Second Contact',
    parentChineseName: 'Parent/Guardian Chinese Name',
    parentEnglishName: 'Parent/Guardian English Name',
    mobile: 'Mobile',
    relation: 'Relation',
    employer: 'Company',
    position: 'Title',
    subject: 'Subject Category',
    courseClass: 'Subject Class',
    teacher: 'Teacher',
    teacherType: 'Teacher Type',
    teacherName: 'Teacher Name',
    medical: 'Medic',
    diet: 'Food',
    national: 'National',
    scholarship: 'Scholarship',
    totalCourse: '{total} classes this week',
    point: 'Point',
    switchChild: 'Switch Child',
    identification: 'Identification',
    passport: 'Passport',
    grading: 'Grading',
    other: 'Other',
    idTypeList: {
      '1260': '@:(student.identification)',
      '1261': '@:(student.passport)',
      '1262': '@:(student.other)',
    },
    downloadReport: 'Download',
    honor: { honorTime: 'Honor Time', honorDetail: 'Honor Detail' },
    leadership: { tenure: 'Tenure', leaderDetail: 'Leadership Detail' },
    label: { honor: 'Honors', leadership: 'Leadership' },
    admissionTime: 'Admission Time',
    report: 'Report',
    finalScore: 'Final Percentage',
    reportName: 'Report Name',
    gradePeriod: 'Grade Period',
    sent: {
      true: 'Sent',
      false: 'Not send',
    },
    gradeBook: 'Gradebook',
    eveningStudyRoom: 'Night Study',
    dormitory: 'Dormitory',
    reportType: 'Report Type',
    reportTypeList: {
      midTerm: 'Mid-term Report',
      endTerm: 'End-term Report',
      fullSchoolYear: 'Yearly Report',
      monthly: 'Monthly Report',
      longTerm: 'Long Term Report',
      exam: 'Exam Report',
    },
    curriculum: 'Curriculum',
    classType: 'Class Type',
    className: 'Class Name',
    classTypeEnum: {
      '1251': 'Homeroom',
      '1253': 'Advisory',
      '1256': 'Night Study',
      '1257': 'Dormitory',
    },
    highestQlfctn: 'Highest Qualification',
    graduationInstitution: 'Graduation Institution',
    weChat: 'WeChat',
    outClass: 'Out Of Class',
    graduationProgress: 'Graduation Progress',
  },
  diary: {
    describe: 'Description',
    diaryDetail: 'Student Log Detail',
    totalPoints: 'Points',
    addIntroduction: 'Add Introduction',
    injuredpartion: 'Injured partion',
    uploadtext: 'Upload file',
    inputText: 'Please input confirm content',
    receipt: 'Recipient',
    sendEmail: 'Send email',
    shareParent: 'Share parent platform',
    shareStudent: 'Share student platform',
    accessory: 'Attachments',
    addStudent: 'Add Student',
    relationstudent: 'Linked Student',
    introduction: 'Introduction',
    place: 'place',
    time: 'Time',
    achievement: 'achievement',
    attendance: 'attendance',
    className: 'Homeroom',
    consultation: 'consultation',
    countNum: 'Num',
    detention: 'detention',
    dormitory: 'dormitory',
    incident: 'incident',
    medical: 'medical',
    points: 'points',
    studentNName: 'studentName',
    diaryType: 'Student Log Type',
    childType: 'Sub Type',
    score: 'Points',
    section: 'Grade',
    class: 'Class',
    house: 'Advisory',
    studentName: 'Name',
    createTime: 'Create Time',
    reset: 'Reset',
    fold: 'Fold',
    unFold: 'unFold',
  },
  homework: {
    inputNotBlank: 'Submit can not be blank',
    saveDraftSuccess: 'Save Draft Success',
    inputHomeworkContent: 'Input Task Content',
    saveDraft: 'Save Draft',
    myHomework: 'My Task',
    type: 'Type',
    lineWork: 'Line Work',
    offLineWork: 'OffLine Work',
    suffixHomeWorkName: 'Task',
    publishScoreConfirm:
      'After successful release, the score cannot be modified, confirm the release?',
    publishScoreSuccess: 'Publish Score Success',
    batchMark: 'Batch Mark',
    finishMark: 'Finish Mark',
    insertFailed: 'Insert Failed',
    uploadImgs: 'Upload Imgs',
    scoreStatus: 'Score Status',
    view: 'View',
    mark: 'Mark',
    scorePublished: { true: 'Published', false: 'Unpublished' },
    studentName: 'Name',
    handInStatus: 'HandIn Status',
    handInTime: 'Hand In Time',
    score: 'Score',
    comments: 'Comments',
    tipOne: 'The deadline is {days} day away',
    tipTwo: '{days} day past the deadline',
    publishPoints: 'Publish Points',
    accessory: 'Attachments',
    remindStudent: 'Remind Student',
    remindSuccess: 'Remind Success',
    complete: 'Complete',
    unComplete: 'unComplete',
    gradeTypeList: {
      '1031': 'No Grade',
      '1032': 'Centesimal',
      '1033': 'Grading',
    },
    selectHandInOnline: 'Please Select HandInOnline',
    selectGradeType: 'Please Select GradeType',
    selectCourse: 'Please Select Course',
    enterHomeworkName: 'Enter Task Name',
    enterHomeworkContent: 'Enter Task Content',
    gradeType: 'Grade Type',
    handInOnline: 'Hand In Online',
    publishHomework: 'Publish Task',
    deadlineTime: 'Deadline',
    statusList: {
      '1011': 'Unsubmit',
      '1012': 'Submited',
      '1013': 'Submited beyond time',
      '1021': 'On going',
      '1022': 'Finished',
    },
    homeContent: 'Home Content',
    name: 'Name',
    sectionName: 'Grade',
    subjectName: 'Subject Category',
    courseName: 'Subject Class',
    completeNum: 'Complete',
    creatorName: 'Creator',
    publishTime: 'Publish Time',
    CCAClass: 'Life Block',
    courseClass: 'Subject Class',
    CCAClassHomework: 'Life Block Task',
    courseClassHomework: 'Subject Class Task',
    handInAfterDead: 'Hand In After Deadline',
    allow: 'Allow',
    disallow: 'Disallow',
  },
  exam: {
    points: 'Score',
    downloadTranscript: 'Export',
    writeComment: 'Write Comment',
    house: 'Advisory',
    teachers: 'Teacher',
    courseClass: 'Subject Class',
    subject: 'Subject Category',
    teacherComment: 'Teacher Comment',
    scholasticAttainment: 'Scholastic Performance',
    addComment: 'Add Comment',
    name: 'Name',
    code: 'Student ID',
    className: 'Homeroom',
    gradings: 'Exam Points',
    comment: 'Comment',
    tipt: 'The exam is finished,only see it',
    tip:
      'The exam is locked and cannot be edited. to edit, please contact the administrator/the exam is over',
    year: 'School Year',
    exam: 'Exam',
    section: 'Grade',
  },
  attendance: {
    date: 'Date',
    statusList: {
      intime: 'Present',
      late: 'Late',
      absent: 'Absent',
      examAbsent: 'Missed Assessment',
      personal: 'Excused Absence',
      illness: 'Sick',
      holiday: 'Vacation',
      exams: 'Exams',
      others: 'Others',
      weekendHoliday: 'Vacation',
      noRecords: 'No Records',
      online: 'Online',
    },
    detailTip1:
      '"P" means punctual, "OL" means online, "L" means late, "EL" means excused late,"A" means absent, "EA" means absent(excused leave),"MA" means missed assessment,"S" means sick, "V" means vacation, "E" means exams, "O" means others',
    detailTip2: 'Move mouse on the icon to view remark',
    dailyPeriod: 'Period',
    classPeriod: 'Class',
    leaveReason: 'Reason',
    leaveTime: 'Leave Time',
  },
  message: {
    attachmentTip3:
      'Single attachment is limited to 100 megabytes, which is not supported by ipad for the time being',
    attachmentTip4: 'Upload attachments. you can use your computer to upload files',
    title: 'Title',
    recipient: 'Recipient',
    sender: 'Sender',
    sendTime: 'Send Time',
    sendMessage: 'Send Message',
    recipientError: 'Please Select Recipient',
    content: 'Content',
    readStatus: 'Read Status',
    attachments: 'Attachments',
    emailNotice: 'Email notice',
    uploadAttachments: 'Upload Attachments',
    attachmentTip1: 'A single attachment is limited to 100m,',
    attachmentTip2: 'add up to 10 attachments',
    enterTitle: 'Please enter title',
    titleLimit: 'The title must not exceed 100 characters',
    enterContent: 'Please enter content',
    addTeachers: 'Add Teachers',
    addParents: 'Add Parents',
    addStudents: 'Add Students',
    invalidFileSize: 'A single attachment cannot exceed 100m',
    invalidFileFormat:
      'Only accept files with suffix of doc, docx, xls, xlsx, ppt, pptx, pdf, jpg, jpeg and png',
    receivedTime: 'Received Time',
    reply: 'Reply',
    history: 'History Message',
    forward: 'Forward',
    recall: 'Recall',
    recalled: 'Recalled',
    recallSuccess: 'Recall Successfully',
    recallFailed: 'Recall failed, the recipient has read the message.',
    newMessageNotice: 'New Message Notice',
    receivedMessage: `You have received new messages:`,
    systemMessage: '1 system message | {num} system messages',
    normalMessage: '1 normal message | {num} normal messages',
  },
  addressBook: {
    name: 'Name',
    displayName: 'Display Name',
    type: 'Type',
    gender: 'Gender',
    position: 'Title',
    email: 'Email',
    switchDisplay: 'Display To Parents and Students',
    editDepartment: 'Edit Department',
    editExternalStaff: 'Edit External Staff',
    addExternalStaff: 'Add External Staff',
    addDepartment: 'Add Department',
    editDepartmetn: 'Edit Departmetn',
    deleteDepartment: 'Delete Department',
    confirmDeleteDepartment: 'Are You Sure TO Delete Department',
    confirmDeleteStaff: 'Are You Sure TO Delete Staff',
    contactNumber: 'Contact Number',
    contactEmail: 'Contact Email',
    introduction: 'Introduction',
    enterName: 'Please input name',
    manageDepartmentMember: 'Manage Department Member',
    externalStaff: 'External Staff',
    manageExternalStaff: 'Manage External Staff',
    staff: 'School Staff',
    addTeacher: 'Add Teacher',
    deleteConfirm: 'Are you sure to delete this staff?',
    trrigerError: 'Fail to display,please try again later',
    enterDepName: 'Please input department name',
    enterlegalEmail: 'Please input legal email',
    enterEmail: 'Please input email',
    teachInfo: 'Teach',
    teacherType: 'Teacher Type',
    categoryDetails: 'Category Details',
  },
  calendar: {
    addSchedule: 'Add Schedule',
    setDate: 'Set working day / vacation',
    parentView: 'View Parent View',
    closeParentView: 'Exit Parent View',
    remark: 'Remark',
    location: 'Location',
    time: 'Time',
    date: 'Date',
    workingDay: 'Working Day',
    holiday: 'Vacation',
    summerHoliday: 'Summer Vacation',
    winterHoliday: 'Winter Vacation',
    visibleToParent: 'Visible to parents and students',
    invisibleToParent: 'Invisible to parents and students',
    holidayPlaceholder: 'Enter vacation name',
    deleteConfirm: 'Are you sure to delete this schedule?',
    semesterStartDate: 'Start of semester',
    semesterEndDate: 'End of semester',
    allDay: 'All Day',
    status: 'Status',
    viewSchedule: 'View Schedule',
    name: 'Name',
  },
  personal: {
    passwordUnLaw: 'The password is 8-20 characters and must contain numbers and letters',
    accountError: 'Do not add yourself ',
    duplicateMobile: 'Duplicate Mobile!',
    forbidden: 'Forbidden',
    oldMobileNotRight: 'Please input right old mobile',
    disableConfirm: 'Are you sure delete the parent?',
    relation: 'Relation',
    mobile: 'Phone Number',
    addParentAccount: 'Add parent account',
    editParentAccount: 'Edit parent account',
    duplicatedRelations: 'relation already existed',
    nameValidateTip: 'Fill in at least one chinese and english name',
    isExistedMobile: 'Mobile is existed',
    textLimit50: 'Limit to 50 characters',
    enterName: 'Please input chinese name',
    enterenName: 'Please input english name',
    modifyMobile: 'Modify mobile',
    studentName: 'Name',
    parentName: 'Parent Name',
    parentAccount: 'Parent Account',
    title: 'Personal Information',
    edit: 'edit',
    name: 'chinese name',
    enName: 'english name',
    sex: 'sex',
    position: 'Title',
    secret: 'secret',
    email: 'Email',
    modifySecret: 'Modify secret',
    nowSecret: 'Now Secret',
    newSecret: 'New Secret',
    confirmSecret: 'Confirm Secret',
    inputSecret: 'please input secret',
    inputNewSecret: 'please input new secret',
    inputConfirmSecret: 'please confirm secret',
    wrongPassword: 'Password are not same.',
    passwordLimit: 'secret wrong or type wrong',
    passwordFomat: 'The password is 8-20 characters and must contain numbers and letters',
  },
  transfer: {
    listTitle: '{title} List',
    selectedNum: 'Selected {num} {title}s',
    deleteAll: 'Delete All',
    selectAll: 'Select All',
    enterName: 'Please enter {title} name',
  },
  weekday: {
    '0': 'Sunday',
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
    '7': 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    short: {
      0: 'Sun',
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
      7: 'Sun',
    },
  },
  notFound: {
    notFound: 'Sorry, the page you visited does not exist.',
    backToHome: 'Back to Home',
  },
  CCAClass: {
    class: 'Life Block',
    mainClass: 'Homeroom',
    status: 'Status',
    age: 'Age',
    studentId: 'Student Id',
    studentName: 'Student',
    enrolledNum: 'Num',
    enrollTime: 'Enroll Time',
    enrollRange: 'Enroll Range',
    classroom: 'Room',
    teacherNames: 'Teacher',
    classTime: 'Time',
    name: 'Life Block',
    selectSchoolYear: 'Please Select School Year',
    schoolYear: 'School Year',
    classDescription: 'Class Description',
    enterDescriptionText: 'Please Enter Description Content',
    cycle: 'Duration',
    minEnrollNum: 'Minimal',
    maxEnrollNum: 'Maximum',
    selectEnrollTime: 'Please Select Enroll Time',
    selectCycle: 'Please Select Duration',
    selectClassTime: 'Please Select Class Time',
    addTeacher: 'Add Teacher',
    selectClassroom: 'Please Select Room',
    selectColleges: 'Please Select Advisory',
    selectGrades: 'Please Select Grades',
    college: 'Advisory',
    grade: 'Grade',
    enterMinEnrollNum: 'Please Enter Minimal',
    enterMaxEnrollNum: 'Please Enter Maximum',
    enterName: 'Please Enter Class Name',
    conflictContext:
      'There are conflicts with the room and teachers of the existing schedule, please adjust it and save it.',
    got: 'Got It',
    conflictType: 'Conflict Type',
    conflictTime: 'Conflict Time',
    conflictDateList: 'Conflict Date',
    conflictName: 'Conflict Name',
    selectAtLeastOne: 'Select at least one between advisory and grade',
    selectTeachers: 'Select at least one teacher',
    addTime: 'Add Time',
    enrolled: 'Enrolled',
    enroll: 'Enroll',
    cancelEnroll: 'cancelEnroll',
    enrollSuccess: 'Enroll Success',
    cancelSuccess: 'Cancel Success',
    enrollConfirm: 'Are you sure to enroll this course?',
    cancelConfirm: 'Are you sure to cancel this enrollment?',
    conflictWithCurriculum: 'Class time is conflict with timetable',
    refreshTips:
      'Every day the life blocks are at the same time, so you can only successfully book parallel classes every day, but you can replace the three sessions according to your preference, and the system will prioritize classes for you in order. courses can be selected every day, and submitted after unified cancellation.',
    note: 'Note',
    yes: 'yes',
    no: 'no',
    isCharge: 'Fee',
    result: 'Result',
    selected: 'selected',
    feeDescription: 'FeeQuote',
    submitEnroll: 'Submit',
    availableNum: 'Available{num}',
    confirmTitle: 'Enroll Info Confirm',
    dConfirmTitle: 'Out-of-school Vendors Course Info Confirm',
    dConfirmTips:
      'The following course is provided by out-of-school vendors. When you sign up, the scholar will automatically drop any previous conflicting life block course that he or she has signed up for. This change cannot be reversed. Please also be informed that some of the out-of-school vendors’ courses may require extra course fees after your enrollment. Are you sure you want to register for the following course?',
    resultTitle: 'Result Info',
    failed: 'Failed({message})',
    reselect: 'Reselect',
    noClassroom: 'No Room',
    order: 'Order',
    schoolCourse: 'School Course',
    outOfSchool: 'Out-Of-School Vendors Course',
    courseType: 'Course Type',
    isCourse: 'Is Subject Class',
    teachingType: 'Teaching Type',
    credit: 'Credit',
  },
  courseMaterial: {
    name: 'Name',
    courseTime: 'Time',
    course: 'Subject Class',
    instruction: 'Instruction',
    attachment: 'Attachment',
    courseClassMaterial: 'Subject Class Resource',
    CCAClassMaterial: 'Life Block Resource',
    courseClass: 'Subject Class',
    CCAClass: 'Life Block',
    subject: 'Subject Category',
    creator: 'Creator',
    createTime: 'CreateTime',
    enterName: 'Please Enter Resource Name',
    selectCourseTime: 'Please Select Course Time',
    materialDetail: 'Resource Detail',
    updater: 'Updater',
    updateTime: 'Update Time',
    fileNum: 'File Num',
  },
  takeLeave: {
    addLeave: 'Apply',
    leaveTime: 'Leave Time',
    leaveType: 'Leave Type',
    leaveDuration: 'Duration',
    approver: 'Approver',
    holiday: 'Vacation',
    illness: 'Sick',
    approved: 'Approved',
    declined: 'Rejected',
    pending: 'Approving',
    retrieved: 'Recalled',
    startTime: 'Start Time',
    endTime: 'End Time',
    selectStartTime: 'Please Select Start Time',
    selectEndTime: 'Please Select End Time',
    reason: 'Reason',
    student: 'Student',
    selectTimeType: 'Please select am or pm',
    selectApprover: 'Please Select Approver',
    recallConfirm: 'Are you sure to recall this leave apply?',
    startLaterThanEnd: 'Start time is later than end time',
    endEarlierThanStart: 'End time is earlier than start time',
    pleaseEnterReason: 'Please Enter Reason',
    calendarConflict: 'The selected date does not include the school day',
    leaveReason: 'Reason',
    leavePerson: 'Leave Person',
    leaveStatus: 'Status',
    leaveDetail: 'Leave Detail',
    rejectReason: 'Reason',
    personal: 'Excused Leave',
    exams: 'Exam',
    others: 'Others',
    apply: 'Apply',
    approveStatus: 'Status',
  },
  moment: {
    expandContent: 'Expand',
    collapseContent: 'Collapse',
    totalLike: 'Total {total} Person Like',
    translation: 'Translation',
  },
  systemMessage: {
    ccaEnrollSuccess: ' life block enroll successfully',
    content: { cca: 'Life block【{name}】has been enrolled successfully' },
  },
  tips: {
    improveMailSuccess: 'Improve Information Successfully',
    nonExistSchool: 'Non-Existent School',
    uploadFailed: 'Upload Failed',
    attachmentTip: 'Single attachment is limited to {size} and add up to {num} attachments',
    rejectFileTips: 'Files with the suffix: {tips} are unacceptable',
    textLimit: 'Limit to {num} characters',
    recallConfirm: 'Are you sure to recall?',
  },
  errorCode: {
    '403': 'Sorry, you do not have access to this page',
    '404': 'Sorry, the page you visited does not exist.',
    '1010': 'Incorrect Account Or Password',
    '1039': 'Incorrect VerCode',
    '1041': 'Account Does Not Exist',
    '1044': 'Account disabled, please contact the teacher',
    560: 'Full enrollment',
    561: 'No class schedule',
    562: 'Already enrolled courses in the same period',
    569: 'Enrollment information has been changed, please refresh the page',
  },
  assignment: {
    totalIncluded: 'Total Included',
    assignmentType: 'Assignment Type',
    assignmentDetail: 'Assignment Detail Of {name}',
    online: 'Online',
    offLine: 'Offline',
    creator: 'Creator',
    publishTime: 'Publish Time',
    topScore: 'Top Score',
    handInAfterDead: 'Hand In After Deadline',
    allow: 'allow',
    disallow: 'disallow',
    Submit: 'Submitted',
    notSubmit: 'Not Submitted',
    myAssignment: 'My Assignment',
    attachment: 'Attachment',
    saveDraft: 'Save Draft',
    scoreAllow: 'Score Allow',
    remark: 'Remark',
    comment: 'Comment',
    incomplete: 'Incomplete',
    late: 'Late',
    missing: 'Missing',
  },
  dormitory: {
    room: 'Room',
    studyRoom: 'Night Study',
    selfStudy: 'Night Study',
  },
  teaching: {
    assignment: 'Assignment',
    resource: 'Resource',
    startDate: 'Start Date',
    taskTip: '无 | 1个进行中的任务未提交 | {total}个进行中的任务未提交',
  },
  courseSelection: {
    grade: 'Grade',
    schoolYear: 'School Year',
    date: 'Date',
    progress: 'Progress',
    title: 'title',
    creditRequirements: 'Credit Requirements',
    enterCredit: 'Please enter credit',
    elective: 'Elective',
    compulsory: 'Compulsory',
    maxCredit: 'Maximum',
    minCredit: 'Minimum',
    questDesign: 'Questionnaire Design',
    exclusive: 'Mutually Exclusive',
    editTopic: 'Edit Topic',
    addTopic: 'Add Topic',
    option: 'Options',
    isCompulsory: 'Compulsory',
    creditLimit: 'Credit Limit',
    choiceLimit: 'Choice Limit',
    enterLimit: 'Please enter limit num',
    optionError: 'No option Exist',
    topicError: 'No topic Exist',
    courseDetail: '(duration：{duration}; credit：{credit} credits)',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    saveAndPublish: 'Save & Publish',
    questPreview: 'Questionnaire Preview',
    questContent: 'Content',
    creditNeedTips: '{credit} credit required',
    optionNeedTips: '{option} options available',
    compulsoryTips:
      'Alternative courses can be added to the elective course type, which is used as an alternative when the first choice cannot be satisfied',
    alternative: 'Alternative Options',
    unpublished: 'unpublished',
    DNS: 'Not Start',
    ended: 'Ended',
    processing: 'Processing',
    setTemplate: 'Set Template',
    applyTemplate: 'Apply Template',
    viewUnmet: 'View Unmet Selection',
    status: 'Status',
    submitted: 'Submitted',
    unsubmitted: 'Unsubmitted',
    selectionDetail: 'Detail',
    schoolYearCourse: '{year} Course',
    selectedCredits: 'Selected Credits：Compulsory：{compulsory} Elective：{elective}',
    totalCredits: 'Total Credits：{total}',
    teacherModifyTips: 'This is the teacher’s revised course selection result',
    viewStudentSelection: "View Student's Selection",
    notPassTips: 'Course: "{course}" have not passed',
    notSelectTips: 'Course: "{course}" have not been selected',
    editResult: 'Edit Selection Result',
    studentSelection: "Student's Course Selection",
    finalScore: 'Final Score',
    earnedCredits: 'Earned Credits',
    selectedCredit: 'Selected Credit',
    selectedCredit2: 'Compulsory：{compulsory} Elective：{elective}',
    creditRequire: 'Credit Requirement',
    totalCreditMax: 'Total Maximum: {credit}',
    totalCreditMin: 'Total Minimum: {credit}',
    addCourse: 'Add Course',
    emptyCourseTips:
      'The student has not submitted the course selection result, you can apply the course selection template or add the course selection to the student',
    templateTips:
      'After the course selection template is set, it will be applied to the students who have not submitted the course selection. If the student submits the course selection result after setting the course selection template, this template will not continue to be applied to the student',
    notice: 'Notice',
    courseLevel: 'Course Level',
    selectNum: 'Select Num',
    courseCapacity: 'Capacity',
    recommendedQuantity: 'Recommended Section Quantity',
    selectionTable: 'Student Selection Table',
    teacherModify: 'Modify By Teacher',
    studentSelect: 'Select By Student',
    deleteConfirm: 'Are you sure to delete this questionnaire?',
    semesters: '1 semester | {count} semesters',
    schoolYears: '1 schoolYear | {count} school years',
    submit: 'Submit',
    statusTips: {
      DNS:
        'The course selection has not started! The course selection results can be submitted only when the course selection time is reached.',
      ended: 'The course selection has ended! No more course selection results can be submitted.',
    },
    warningTips:
      'The course selection does not meet the credit requirements, please modify and submit',
    totalCredit: 'Total Credit',
    mutexGroup: 'Incompatible group',
    topicDes: 'Topic Description',
  },
  address: {
    campusAddress: 'Campus Address Book',
    personalAddress: 'Personal Address Book',
    1251: 'Homeroom',
    1253: 'Advisory',
    1255: 'Subject Class',
    1257: 'Dormitory',
    1256: 'Night Study',
    1258: 'Life Block',
    email: 'email',
    mobile: 'mobile',
    copy: 'copy',
    exterPerson: 'External Person',
    copySuccess: 'Copy Succeeded!',
  },
  announcement: {
    all: 'All',
    campusActivity: 'Campus Activite',
    campusPolicy: 'Campus Policy',
    attention: 'Attention',
    search: 'Search',
    edit: 'The announcement was edited at {time}',
    department: 'Department',
    time: 'Time',
    status: 'Status',
    publisher: 'Publisher',
    isTop: 'Top Or Not',
    topEndTime: 'Top End Time',
    published: 'Published',
    unpublished: 'Unpublished',
    yes: 'Yes',
    no: 'No',
    keyword: 'Keyword',
    date: 'Date',
    tag: 'Tag',
  },
  graduation: {
    graduationRqm: 'Graduation Standard',
    progress: 'Graduation Progress',
    compulsoryCredit: 'Compulsory Credits',
    electiveCredit: 'Elective Credits',
    finishedCredit: 'Finished Credit',
    finished: 'Finished',
    inProgress: 'In Progress',
    courseAdmission: 'Course Criteria{index}',
    elective: 'Elective',
    gotCredits: 'Got Credits',
    course: 'Course',
    remark: 'Remark',
    credit: 'Credit',
    viewRequirements: 'View Graduation Standard',
    totalCredit: 'Total credits',
    compulsoryLabel: 'Compulsory (credits: {credit})',
    mark: 'Mark',
    courseGroup: 'Course Group{index}',
    electiveLabel: 'Elective (credits: {credit})',
    maxRecognizedCreitds: 'Maximum Recognized Creitds',
    EXTRA: 'Out-Of-School Course',
    OVERFLOW: 'Exceeding the required conditions, counted as being selected for repair',
  },
}























































import { Vue, Component, Prop, Emit, Model, Watch } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'

@Component
export default class setMailModal extends Vue {
  private confirmLoading: boolean = false
  private mailForm: any
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly type!: any
  @Prop() private readonly email!: any

  private confirm(): void {
    this.confirmLoading = true
    const form = this.mailForm
    form.validateFields((err: any, values: any) => {
      if (!err) {
        let condition = {
          email: values.email,
        }
        StudentController.updateUserInfo(condition)
          .then(res => {
            if (res.data.success) {
              this.$message.success(this.$tc('tips.improveMailSuccess'))
              this.$emit('success')
            } else {
              const email = form.getFieldValue('email')
              form.setFields({
                ['email']: {
                  value: email,
                  errors: [{ message: res.data.resMsg, field: 'email' }],
                },
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.confirmLoading = false
          })
      } else {
        this.confirmLoading = false
        return
      }
    })
  }

  private beforeCreate(): void {
    this.mailForm = this.$form.createForm(this)
  }

  @Watch('email')
  private onEmailChange(val) {
    this.$nextTick(() => {
      if (!this.visible) return
      this.mailForm.setFieldsValue({ email: val })
    })
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }
}

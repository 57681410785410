




















































import { Component, Vue } from 'vue-property-decorator'
import { RouteRecord } from 'vue-router'
import { getSchoolInfo } from '@/utils/utils'

@Component
export default class PadMenu extends Vue {
  private menuKeys: Array<string> = []
  private menuOpenKeys: Array<string> = []
  private newMessage = 0

  private get subRoutes(): any {
    return this.$store.state.routes[0].children.filter(child => !child.hidden)
  }

  private get routes(): Array<any> {
    const router = this.$route as any
    return router.matched.filter((item: RouteRecord) => item.meta && item.meta.routerName)
  }

  private get schoolId(): number {
    return getSchoolInfo().schoolId
  }

  private get menus(): Array<object> {
    return this.subRoutes
  }

  private created(): void {
    const routes = this.routes
    this.menuOpenKeys = routes[2]
      ? [routes[1].name, routes[0].name]
      : routes[1]
      ? [routes[0].name]
      : []
    this.menuKeys = routes[2] ? [routes[2].name] : routes[1] ? [routes[1].name] : [routes[0].name]
  }

  private clickTopMenu({ key }: { key: string }): void {
    this.$router.push({ name: key }).catch(err => {})
  }
}

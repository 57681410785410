import Vue from 'vue'
import moment from 'moment'

const momentFilter = function(dataStr: string, pattern = 'YYYY-MM-DD HH:mm:ss', locale?) {
  let current = moment(dataStr)
  locale && current.locale(locale)
  return dataStr ? current.format(pattern) : ''
}

const doubleMomentFilter = function(time: any[], options?) {
  const [start, end] = time
  let tString = ''
  let pattern = 'YYYY-MM-DD HH:mm:ss'
  let secondPat
  let split = '-'
  let locale = undefined
  if (typeof options === 'string') {
    pattern = options
  } else {
    pattern = options?.pattern || pattern
    split = options?.split || split
    locale = options?.locale
    secondPat = options?.secondPat
  }
  if (!secondPat) {
    const sString = momentFilter(start, pattern, locale)
    const eString = momentFilter(end, pattern, locale)
    tString = `${sString} ${sString && eString ? split : ''} ${eString}`.trim()
  } else {
    const fString = momentFilter(start, pattern, locale)
    const ssString = momentFilter(start, secondPat, locale)
    const seString = momentFilter(end, secondPat, locale)
    tString = `${fString} ${ssString} ${ssString && seString ? split : ''} ${seString}`.trim()
  }
  return tString
}

Vue.filter('moment', momentFilter)
Vue.filter('doubleMoment', doubleMomentFilter)

/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
export const routerRightCode = {
  1: 'studentDetail',
  2: 'homework',
  3: 'homeworkDetail',
  4: 'diary',
  5: 'attendance',
  6: 'calendar',
  7: {
    name: 'message',
    children: ['inbox', 'outbox', 'sendMessage', 'outDetail', 'inDetail', 'replyMessage'],
  },
  10: 'moment',
  11: 'courseMaterial',
  12: 'CCAClass',
  13: 'assignment',
  14: 'assignmentDetail',
  15: 'points',
  16: 'materialInCourse',
  17: 'teaching',
  18: 'courseSelection',
  19: {
    name: 'addressBook',
    children: ['schoolAddressBook', 'privateAddressBook'],
  },
  20: 'announcement',
  21: 'announcementDetail',
  22: 'curriculum',
  23: 'report',
}
export const kindergarten = [20, 1, 5, 6, 19, 21]
export const all = [
  20,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  21,
  22,
  23,
]

export const studentStatus = {
  1014: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  1016: [1, 4, 5, 15, 22, 23],
  1017: [1, 19, 22],
  1018: [1, 4, 5, 15, 22, 23],
}

export function createPagination({
  pageSize,
  current,
  defaultCurrent,
  defaultPageSize,
  pageSizeOptions,
  showQuickJumper,
  showSizeChanger,
  showTotal,
}: any) {
  return {
    defaultCurrent: defaultCurrent || 1,
    defaultPageSize: defaultPageSize || 30,
    pageSizeOptions: pageSizeOptions || ['10', '20', '30', '50'],
    showQuickJumper: showQuickJumper || true,
    showSizeChanger: showSizeChanger || true,
    showTotal: showTotal,
    pageSize: pageSize || 30,
    current: current === 0 ? current : current || 1,
    total: 0,
  }
}

export const waitFor = 3000

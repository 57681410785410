export default {
  platformName: '学生平台',
  changeLocale: 'English',
  staticCode: {
    1201: '未开始',
    1202: '进行中',
    1203: '已结束',
    1204: '上学期',
    1205: '下学期',
    1208: '未开启',
    1209: '进行中',
    1210: '已毕业',
    //---- CCA状态码start -----//
    1001: '未开始',
    1021: '可报名',
    1022: '已报名',
    1023: '已拒绝',
    1024: '报名成功',
    1025: '报名失败',
    1026: '已报满',
    1011: '每周重复',
    1012: '隔周重复',
    1013: '只一次',
    //---- CCA状态码end -----//
  },
  relation: {
    father: '爸爸',
    mother: '妈妈',
    patGrandPa: '爷爷',
    patGrandMa: '奶奶',
    matGrandPa: '外公',
    matGrandMa: '外婆',
    other: '其他',
  },
  router: {
    homeworkDetail: '任务详情',
    points: '成绩册',
    studentDetail: '学生详情',
    homework: '任务',
    diary: '日记簿',
    addDiary: '添加日记簿',
    editDiary: '编辑日记簿',
    performance: '成绩',
    attendance: '考勤',
    calendar: '校历',
    message: '消息通知',
    inbox: '收件箱',
    outbox: '发件箱',
    sendMessage: '发消息',
    sentDetail: '消息详情',
    replyMessage: '回复消息',
    receivedDetail: '消息详情',
    personal: '个人中心',
    systemMessage: '系统消息',
    CCAClass: '延展课程',
    CCAClassDetail: '查看延展课程',
    homeworkViewer: '任务列表',
    homeworkViewerChild: {
      CCAClass: '延展课程任务',
      courseClass: '课程班级任务',
    },
    courseMaterial: '课程资料',
    moment: '班级圈',
    assignment: '任务',
    assignmentDetail: '任务详情',
    teaching: '教学课程',
    courseSelection: '选课管理',
    addressBook: '通讯录',
    schoolAddressBook: '校园通讯录',
    privateAddressBook: '个人通讯录',
    announcement: '校园公告',
    announcementDetail: '公告详情',
    curriculum: '课表',
    report: '报告',
  },
  login: {
    userLogin: '用户登录',
    account: '学号或邮箱',
    password: '密码',
    enterAccount: '请输入学号或邮箱',
    enterPassword: '请输入密码',
    loginAuto: '7天内自动登录',
    login: '登录',
    forgetPassword: '忘记密码',
    mobile: '手机号',
    verCode: '验证码',
    enterVerCode: '请输入验证码',
    enterMobile: '请输入手机号',
    getVerCode: '获取验证码',
    resendVerCode: '{time}秒后重新发送',
    resetPwdTip: '密码为8-20个字符,必须包含数字和字母',
    confirmPwd: '确认密码',
    inconsistentPwd: '密码不一致',
    invalidPwd: '密码不符合规范',
    setPassword: '设置登录密码',
    enter: '进入',
    enterMail: '请输入邮箱',
    firstLogin: '首次登录',
    improveInfo: '完善邮箱信息',
    improveTips: '完善邮箱信息后,您可以使用邮箱登录系统',
    tips:
      '推荐使用谷歌浏览器及最新版Edge。兼容Safari。不推荐使用分辨率低于1366*768的电脑及手机查看。不兼容IE浏览器。',
    mailTips: '验证码已发送至{tips}',
    enterStudentId: '请输入学号',
  },
  common: {
    integral: '整数',
    unsaveConfirm: '内容未保存,确认退出?',
    typeError: '格式错误',
    textLimit11: '号码限制11位',
    submit: '提交',
    clear: '清空',
    seq: '序号',
    operations: '操作',
    total: '一共：{total}条',
    add: '新增',
    edit: '编辑',
    delete: '删除',
    reset: '重置',
    resetPwd: '重置密码',
    enter: '请输入',
    select: '请选择',
    confirm: '确定',
    cancel: '取消',
    true: '是',
    false: '否',
    gender: {
      male: '男',
      female: '女',
    },
    saveSuccess: '保存成功',
    deleteSuccess: '删除成功',
    resetSuccess: '重置成功',
    save: '保存',
    all: '全部',
    submitSuccess: '提交成功',
    terminateSuccess: '终止成功',
    adjustSuccess: '调课成功',
    attendanceSuccess: '考勤成功',
    view: '查看',
    enterContent: '请输入内容',
    sendVerCodeSuccess: '验证码发送成功',
    setPasswordSuccess: '设置密码成功',
    send: '发送',
    sendSuccess: '发送成功',
    download: '导出',
    export: '导出',
    success: '成功',
    downloadResource: '下载课程资料',
    pageTotal: '总共 {total} 条',
    date: '日期',
    loadMore: '加载更多...',
    noMore: '没有更多数据了',
    refreshPage: '刷新页面',
    finish: '完成',
    email: '邮箱',
    subjectClass: '课程班级',
    name: '名称',
    deadline: '截止时间',
    type: '类型',
    subject: '科目',
    description: '描述',
    status: '状态',
    content: '内容',
    attachment: '附件',
    schoolYear: '学年',
    term: '学期',
    level: '等级',
    backToHome: '返回首页',
    logout: '登出',
    score: '得分',
    grade: '年级',
    createTime: '创建时间',
    CCAClass: '延展课程',
    creator: '创建人',
    teacher: '老师 | 老师',
    time: '时间',
    weekday: '日期',
    fold: '折叠',
    unfold: '展开',
    classroom: '教室',
    last: '上一个',
    next: '下一个',
    deleteConfirm: '确定要删除吗?',
    copySuccess: '复制成功',
    copy: '复制',
    selected: '已选择 {total} 条',
    readAll: '全部已读',
    readSuccess: '设置已读成功',
  },
  main: {
    quit: '退出',
    helpDoc: '帮助文档',
    feedback: '意见反馈',
    feedbackTips: '使用过程中若遇到技术问题， 请发送邮件到学校邮箱：',
    introduction: 'IntSchool功能说明',
    guide: '新手引导',
    moreDetails: '更多操作,详见帮助文档',
  },
  intros: {
    studentDetail: '详细的电子档案(课表、学期报告、教师信息)',
    teaching: '便捷了解学习任务, 随时查看课程资料',
    gradeBook: '课业进度一目了然',
    diary: '学生的奖惩、生活、健康等全方位成长记录',
    moment: '班级、校园动态一看便知',
    attendance: '实时同步学生课堂考勤状态',
    message: '家校沟通更顺畅, 邮件往来, 拒绝信息轰炸',
    parentsMeeting: '在线快速预约家长会, 时段明确, 行程清晰',
    takeLeave: '无需繁琐步骤, 请假流程快速方便',
    calendar: '一键直达校历信息, 日程安排更清晰',
    lifeBlock: '延展课程选修课选课报名方便快捷',
  },
  student: {
    parent: '家长',
    studentInfo: '学生信息',
    parentInfo: '家长信息',
    curriculumInfo: '课表信息',
    teacherInfo: '教师信息',
    classInfo: '班级信息',
    subjectInfo: '课程信息',
    attendanceInfo: '考勤信息',
    instruction: '说明',
    chineseName: '姓名（中文）',
    englishName: '英文名',
    firstName: '名（拼音）',
    lastName: '姓（拼音）',
    gender: '性别',
    birthday: '出生日期',
    idType: '证件类型',
    idNumber: '证件号码',
    nationality: '国籍（以护照为准）',
    email: '邮箱',
    residentialAddress: '居住地址',
    address: '详细地址',
    section: '年级',
    class: '班级',
    studentId: '学号',
    house: '学院',
    startYear: '入学年份',
    departureTime: '离校时间',
    schoolBus: '校车',
    route: '路线',
    placeToMeet: '迎接地点',
    accommodation: '住宿',
    platformLoginRight: '平台登录权限',
    loginAccount: '登录账号',
    password: '密码',
    resetPassword: '重置密码',
    siblingsInfo: '兄弟姐妹信息',
    childName: '孩子姓名',
    firstParent: '主家长信息',
    secondParent: '第二家长信息',
    parentChineseName: '家长/监护人姓名',
    parentEnglishName: '家长/监护人英文名',
    mobile: '移动电话',
    relation: '与学生关系',
    employer: '工作单位',
    position: '职位',
    subject: '科目',
    courseClass: '课程班级',
    teacher: '教师',
    teacherType: '教师类型',
    teacherName: '教师姓名',
    medical: '医疗',
    diet: '饮食',
    national: '民族',
    scholarship: '奖学金',
    totalCourse: '本周{total}节课',
    point: '分数',
    switchChild: '切换孩子',
    identification: '身份证',
    passport: '护照',
    other: '其他',
    grading: '成绩',
    idTypeList: {
      '1260': '@:(student.identification)',
      '1261': '@:(student.passport)',
      '1262': '@:(student.other)',
    },
    downloadReport: '下载报告',
    honor: {
      honorTime: '荣誉时间',
      honorDetail: 'Honor详情',
    },
    leadership: {
      tenure: '任职时间',
      leaderDetail: 'Leadership详情',
    },
    label: {
      honor: 'Honors',
      leadership: 'Leadership',
    },
    admissionTime: '入学时间',
    report: '报告',
    finalScore: '最终得分',
    reportName: '报告名称',
    gradePeriod: '报告期',
    sent: {
      true: '已发送',
      false: '未发送',
    },
    gradeBook: '成绩册',
    eveningStudyRoom: '自习室',
    dormitory: '宿舍',
    reportType: '报告类型',
    reportTypeList: {
      midTerm: '期中报告',
      endTerm: '期末报告',
      fullSchoolYear: '学年报告',
      monthly: '月度报告',
      longTerm: '长报告',
      exam: '考试结果报告',
    },
    curriculum: '课程',
    classType: '班级类型',
    className: '班级名称',
    classTypeEnum: {
      '1251': '主班级',
      '1253': '学院',
      '1256': '自习室',
      '1257': '宿舍',
    },
    highestQlfctn: '最高学历',
    graduationInstitution: '毕业院校',
    weChat: '微信号',
    outClass: '已退班',
    graduationProgress: '毕业进度',
  },
  homework: {
    inputNotBlank: '提交的任务不能为空',
    saveDraftSuccess: '草稿已保存',
    inputHomeworkContent: '请输入内容',
    saveDraft: '存草稿',
    myHomework: '我的任务',
    type: '类型',
    lineWork: '线上任务',
    offLineWork: '线下任务',
    suffixHomeWorkName: '的任务',
    publishScoreConfirm: '发布成功后分数不可修改，确认发布?',
    publishScoreSuccess: '发布成功',
    batchMark: '批量评分',
    finishMark: '完成评分',
    insertFailed: '插入失败',
    uploadImgs: '上传图片',
    scoreStatus: '得分状态',
    view: '查看',
    mark: '评分',
    scorePublished: {
      true: '已发布',
      false: '未发布',
    },
    studentName: '学生姓名',
    handInStatus: '完成状态',
    handInTime: '提交时间',
    score: '分数',
    comments: '评语',
    tipTwo: '超过截止日期{days}天',
    tipOne: '距离截止日期还有{days}天',
    publishPoints: '发布得分',
    accessory: '附件',
    remindStudent: '提醒学生',
    remindSuccess: '已发送通知提醒学生交任务',
    complete: '已完成',
    unComplete: '未完成',
    gradeTypeList: {
      1031: '不评分',
      1032: '百分制',
      1033: '等级制',
    },
    selectHandInOnline: '请选择是否线上交任务',
    selectGradeType: '请选择评分方式',
    selectCourse: '请选择课程班级',
    enterHomeworkName: '输入家庭任务名称',
    enterHomeworkContent: '输入任务内容',
    gradeType: '评分方式',
    handInOnline: '线上交任务',
    publishHomework: '发布任务',
    deadlineTime: '截止时间',
    statusList: {
      1011: '未完成',
      1012: '按时完成',
      1013: '逾期完成',
      1021: '进行中',
      1022: '已结束',
    },
    homeContent: '任务内容',
    name: '任务名称',
    sectionName: '年级',
    subjectName: '科目',
    courseName: '课程班级',
    completeNum: '完成情况',
    creatorName: '发布人',
    publishTime: '发布时间',
    CCAClass: '延展课程',
    courseClass: '课程班级',
    CCAClassHomework: '延展课程任务',
    courseClassHomework: '课程班级任务',
    handInAfterDead: '截止后可提交',
    allow: '是',
    disallow: '否',
  },
  diary: {
    describe: '描述',
    diaryDetail: '日记簿详情',
    totalPoints: '总分',
    addIntroduction: '添加描述',
    injuredpartion: '受伤部位',
    uploadtext: '上传文件',
    inputText: '请输入通知内容',
    receipt: '收件人',
    sendEmail: '发送站内信',
    shareParent: '分享家长平台',
    shareStudent: '分享学生平台',
    accessory: '附件',
    addStudent: '添加学生',
    relationstudent: '关联学生',
    introduction: '描述',
    place: '地点',
    time: '时间',
    achievement: '成就',
    attendance: '考勤异常',
    className: '主班级',
    consultation: '顾问咨询',
    countNum: '数量',
    detention: '罚时',
    dormitory: '宿舍',
    incident: '事件',
    medical: '护士来访',
    points: '分数',
    studentNName: '姓名',
    diaryType: '日记簿类型',
    childType: '子类型',
    score: '分数',
    section: '年级',
    class: '班级',
    house: '学院',
    studentName: '学生姓名',
    createTime: '创建时间',
    reset: '重置',
    fold: '折叠',
    unFold: '展开',
  },
  exam: {
    points: '成绩',
    downloadTranscript: '下载报告',
    writeComment: '写评语',
    house: '学院',
    teachers: '老师',
    courseClass: '课程班级',
    subject: '科目',
    teacherComment: '教师评语',
    scholasticAttainment: '学业情况',
    addComment: '添加评语',
    name: '姓名',
    code: '学号',
    className: '主班级',
    gradings: '考试成绩',
    comment: '评语',

    tipt: '考试已结束,只可查看',
    tip: '考试已锁定，无法编辑，若要编辑，请联系管理员/考试已结束，只可查看',
    year: '学年',
    exam: '考试',
    section: '年级',
  },
  attendance: {
    date: '日期',
    statusList: {
      intime: '出席',
      late: '迟到',
      absent: '缺席',
      examAbsent: '缺考',
      personal: '缺席(可谅解)',
      illness: '病假',
      holiday: '假期',
      exams: '考试',
      others: '其他',
      weekendHoliday: '假期',
      noRecords: '未考勤',
      online: '在线',
    },
    detailTip1:
      '出席：P，在线：OL，迟到：L，迟到（可谅解）：EL，缺席：A，缺席（可谅解）：EA，缺考：MA，病假：S，假期：V，考试：E，其他：O',
    detailTip2: '将鼠标移到图标上查看详细信息',
    dailyPeriod: '课期',
    classPeriod: '课堂',
    leaveReason: '请假事由',
    leaveTime: '请假时间',
  },
  message: {
    attachmentTip3: '单个附件限制100M以内,ipad暂不支持',
    attachmentTip4: '上传附件，可以使用电脑上传文件',
    title: '标题',
    recipient: '收件人',
    sender: '发件人',
    recipientError: '请选择收件人',
    sendTime: '发件时间',
    sendMessage: '发消息',
    content: '内容',
    readStatus: '已读情况',
    attachments: '附件',
    emailNotice: '邮件通知',
    uploadAttachments: '上传附件',
    attachmentTip1: '单个附件限制100M以内,',
    attachmentTip2: '最多添加10个附件',
    enterTitle: '请输入标题',
    titleLimit: '标题不能超过100字符',
    enterContent: '请输入内容',
    addTeachers: '添加教师',
    addParents: '添加家长',
    addStudents: '添加学生',
    invalidFileSize: '单个附件大小不能超过100M',
    invalidFileFormat: '仅接受后缀名为doc, docx, xls, xlsx, ppt, pptx, pdf, jpg, jpeg或png的文件',
    receivedTime: '收件时间',
    reply: '回复',
    history: '历史消息',
    forward: '转发',
    recall: '撤回',
    recalled: '已撤回',
    recallSuccess: '撤回成功',
    recallFailed: '撤回失败,收件人已读消息',
    newMessageNotice: '新消息提醒',
    receivedMessage: `您收到了新的消息: `,
    systemMessage: '{num}条系统消息',
    normalMessage: '{num}条普通消息',
  },
  addressBook: {
    name: '姓名',
    displayName: '显示名称',
    type: '类型',
    gender: '性别',
    position: '职务',
    email: '邮箱',
    switchDisplay: '显示在家长和学生端',
    editDepartment: '编辑部门',
    editExternalStaff: '编辑外部人员',
    addExternalStaff: '添加外部人员',
    addDepartment: '新增部门',
    editDepartmetn: '编辑部门',
    deleteDepartment: '删除部门',
    confirmDeleteDepartment: '确认删除该部门？',
    confirmDeleteStaff: '确认删除该员工？',
    contactNumber: '联系电话',
    contactEmail: '联系邮箱',
    introduction: '简介',
    enterName: '请输入姓名',
    manageDepartmentMember: '管理部门人员',
    externalStaff: '外部人员',
    manageExternalStaff: '管理外部人员',
    staff: '校内人员',
    addTeacher: '添加教师',
    deleteConfirm: '确认删除？',
    trrigerError: '保存失败请重试',
    enterDepName: '请输入部门名称',
    enterlegalEmail: '请输入合法邮箱',
    enterEmail: '请输入邮箱',
    teachInfo: '教学信息',
    teacherType: '教师类型',
    categoryDetails: '分组详情',
  },
  calendar: {
    addSchedule: '新增日程',
    setDate: '设置工作日/假期',
    parentView: '查看家长视图',
    closeParentView: '关闭家长视图',
    remark: '备注',
    location: '地点',
    time: '时间',
    date: '日期',
    workingDay: '工作日',
    holiday: '假期',
    summerHoliday: '暑假',
    winterHoliday: '寒假',
    visibleToParent: '在家长端和学生端显示',
    invisibleToParent: '在家长端和学生端不显示',
    holidayPlaceholder: '输入假期名称',
    deleteConfirm: '确定要删除这条日程吗？',
    semesterStartDate: '学期开始日',
    semesterEndDate: '学期结束日',
    allDay: '全天',
    status: '状态',
    viewSchedule: '查看日程',
    name: '名称',
  },
  personal: {
    passwordUnLaw: '密码为8-20个字符,必须包含数字和字母',
    passwordFomat: '密码为8-20个字符,必须包含数字和字母',
    accountError: '不要添加你自己哈',
    duplicateMobile: '重复账号!',
    forbidden: '已禁用',
    oldMobileNotRight: '请输入正确原始号码',
    disableConfirm: '确认删除家长?',
    relation: '与学生关系',
    duplicatedRelations: '关系已存在',
    nameValidateTip: '中英文名二选一必填',
    isExistedMobile: '号码已存在',
    textLimit50: '限制50字以内',
    enterName: '请输入中文名',
    enterenName: '请输入英文名',
    inputMobile: '密码错误或格式不正确',
    oldMobile: '原手机号码',
    addParentAccount: '添加家属账号',
    editParentAccount: '修改家属账号',

    modifyMobile: '修改手机号',
    studentName: '学生姓名',
    mobile: '手机号',
    parentName: '家长姓名',
    parentAccount: '家属账号',
    title: '个人信息',
    edit: '编辑',
    name: '中文名',
    enName: '英文名',
    sex: '性别',
    position: '职务',
    secret: '密码',
    email: '邮箱',
    modifySecret: '修改密码',
    nowSecret: '当前密码',
    newSecret: '新密码',
    confirmSecret: '确认密码',
    inputSecret: '请输入登录密码',
    inputNewSecret: '请输入新密码',
    inputConfirmSecret: '请确认密码',
    wrongPassword: '两次密码不同',
    passwordLimit: '密码错误或格式不正确',
  },
  transfer: {
    listTitle: '{title}列表',
    selectedNum: '已选择{title}{num}人',
    deleteAll: '全部删除',
    selectAll: '全选',
    enterName: '请输入{title}姓名',
  },
  weekday: {
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    0: '周日',
    1: '周一',
    2: '周二',
    3: '周三',
    4: '周四',
    5: '周五',
    6: '周六',
    7: '周日',
    short: {
      0: '周日',
      1: '周一',
      2: '周二',
      3: '周三',
      4: '周四',
      5: '周五',
      6: '周六',
      7: '周日',
    },
  },
  notFound: {
    notFound: '抱歉，您访问的页面不存在。',
    backToHome: '返回首页',
  },
  CCAClass: {
    class: '课程',
    status: '状态',
    enrolledNum: '已报名',
    enrollTime: '报名时间',
    classroom: '教室',
    teacherNames: '教师',
    enrollRange: '允许报名范围',
    classTime: '时间',
    name: '课程名称',
    selectSchoolYear: '请选择学年',
    schoolYear: '学年',
    classDescription: '课程描述',
    cycle: '周期',
    minEnrollNum: '最低报名数',
    maxEnrollNum: '最大报名数',
    enterDescriptionText: '请输入课程描述内容',
    selectEnrollTime: '请选择报名时段',
    selectCycle: '请选择周期',
    selectClassTime: '请选择时间',
    addTeacher: '添加教师',
    selectClassroom: '请选择教室',
    selectColleges: '请选择学院',
    selectGrades: '请选择年级',
    college: '学院',
    grade: '年级',
    enterMinEnrollNum: '请输入最低报名数',
    enterMaxEnrollNum: '请输入最大报名数',
    enterName: '请输入教程名称',
    conflictContext: '与现有课表的教室、教师存在冲突,请调整后再保存',
    got: '知道了',
    conflictType: '冲突类型',
    conflictTime: '冲突时间',
    conflictDateList: '冲突日期',
    conflictName: '冲突字段',
    selectAtLeastOne: '年级和学院至少选择其中一个',
    addTime: '添加时间',
    age: '年龄',
    studentId: '学号',
    studentName: '姓名',
    mainClass: '主班级',
    selectTeachers: '至少选择一个老师',
    enrolled: '已报名',
    enroll: '报名',
    cancelEnroll: '取消报名',
    enrollSuccess: '报名成功',
    cancelSuccess: '取消成功',
    enrollConfirm: '是否确认报名',
    cancelConfirm: '是否确认取消报名',
    conflictWithCurriculum: '时间与课表冲突',
    refreshTips:
      '每天的延展课程都在相同的时间上课，所以每天你只能成功报上一节课，但是你可以按照喜爱程度勾选三节，系统会按顺序优先为您抢课。每天都可以选课，统一勾选后提交。',
    note: '备注',
    yes: '是',
    no: '否',
    isCharge: '是否收费',
    result: '报名结果',
    selected: '已选择',
    submitEnroll: '提交报名',
    feeDescription: '收费报价',
    availableNum: '可报名{num}',
    confirmTitle: '确认报名信息',
    dConfirmTitle: '供应商课程信息确认',
    dConfirmTips:
      '您报名的以下课程为校外课程供应商所提供的课程，若报名成功，则学生将从之前已注册的同时段的延展课程中自动退出，且报名成功后不可自行取消报名。同时，校外课程供应商所提供的课程可能会产生相应的课程服务费，请您确认是否报名如下课程？',
    resultTitle: '结果信息',
    failed: '失败({message})',
    reselect: '重新选择',
    noClassroom: '暂无教室',
    order: '优先级',
    schoolCourse: '学校课程',
    outOfSchool: '供应商课程',
    courseType: '课程类型',
    isCourse: '是否为课程班级',
    teachingType: '教学类型',
    credit: '学分',
  },
  courseMaterial: {
    name: '资料名称',
    courseTime: '课程时间',
    course: '课程',
    instruction: '说明',
    attachment: '附件',
    courseClassMaterial: '课程班级课程资料',
    CCAClassMaterial: '延展课程课程资料',
    courseClass: '课程班级',
    CCAClass: '延展课程',
    subject: '科目',
    creator: '发布人',
    createTime: '发布时间',
    enterName: '请输入课程资料名称',
    selectCourseTime: '请选择课程时间',
    materialDetail: '课程资料详情',
    updater: '更新人',
    updateTime: '更新时间',
    fileNum: '文件数',
  },
  takeLeave: {
    addLeave: '发起请假',
    leaveTime: '请假时间',
    leaveType: '请假类型',
    leaveDuration: '请假时长',
    approver: '审批人',
    holiday: '假期',
    illness: '病假',
    approved: '已通过',
    declined: '已拒绝',
    pending: '待审批',
    retrieved: '已撤销',
    startTime: '开始时间',
    endTime: '结束时间',
    selectStartTime: '请选择开始时间',
    selectEndTime: '请选择结束时间',
    reason: '请假事由',
    student: '学生',
    selectTimeType: '请选择上下午',
    selectApprover: '请选择审批人',
    recallConfirm: '是否确认撤回该申请',
    startLaterThanEnd: '开始时间不能晚于结束时间',
    endEarlierThanStart: '结束时间不能早于开始时间',
    pleaseEnterReason: '请填写请假原因',
    calendarConflict: '所选日期不包含上课日，无需请假',
    leavePerson: '请假人',
    leaveStatus: '请假状态',
    leaveReason: '请假原因',
    leaveDetail: '请假详情',
    rejectReason: '拒绝原因',
    personal: '缺席(可谅解)',
    exams: '考试',
    others: '其他',
    apply: '回复',
    approveStatus: '审批状态',
  },
  moment: {
    expandContent: '展开全文',
    collapseContent: '折叠全文',
    totalLike: '共{total}人觉得很赞',
    translation: '翻译',
  },
  systemMessage: {
    ccaEnrollSuccess: '延展课程报名成功',
    content: {
      cca: '您已成功报名延展课程【{name}】',
    },
  },
  tips: {
    improveMailSuccess: '完善邮箱成功',
    nonExistSchool: '查无此学校',
    uploadFailed: '上传失败',
    attachmentTip: '单个附件限制{size}以内, 最多添加{num}个附件',
    rejectFileTips: '不可上传后缀名为{tips}的文件',
    textLimit: '限制{num}字以内',
    recallConfirm: '是否确认撤回?',
  },
  errorCode: {
    1010: '账号或密码错误',
    1039: '验证码错误',
    1041: '账号不存在',
    1044: '您的账号已被禁用,请联系学校老师',
    403: '抱歉，你无权访问该页面',
    404: '抱歉，您访问的页面不存在。',
    560: '报名人数已满',
    561: '无课程安排',
    562: '已报名同时段的课程',
    569: '报名信息已更改, 请刷新页面',
  },
  assignment: {
    totalIncluded: '计入总分',
    online: '线上任务',
    offline: '线下任务',
    assignmentType: '任务类型',
    creator: '发布人',
    publishTime: '发布时间',
    topScore: '最高分',
    handInAfterDead: '截止后可提交',
    allow: '是',
    disallow: '否',
    submitStatus: '提交状态',
    submitTime: '提交时间',
    Submit: '已提交',
    notSubmit: '未提交',
    assignmentDetail: '{name}的任务详情',
    myAssignment: '我的任务',
    saveDraft: '存草稿',
    scoreAllow: '是否评分',
    remark: '标记',
    comment: '评语',
    incomplete: '未完成',
    late: '迟交',
    missing: '缺交',
  },
  dormitory: {
    room: '宿舍',
    studyRoom: '自习室',
    selfStudy: '自习',
  },
  teaching: {
    assignment: '任务',
    resource: '资料',
    startDate: '开启时间',
    taskTip: '无 | 1个进行中的任务未提交 | {total}个进行中的任务未提交',
  },
  courseSelection: {
    grade: '选课年级',
    schoolYear: '选课学年',
    date: '选课时间',
    progress: '选课进度',
    title: '标题',
    creditRequirements: '学分要求',
    enterCredit: '请输入学分',
    elective: '选修',
    compulsory: '必修',
    maxCredit: '最大学分',
    minCredit: '最小学分',
    questDesign: '问卷设计',
    exclusive: '互斥',
    editTopic: '编辑选题',
    addTopic: '新增选题',
    option: '选项',
    isCompulsory: '是否必修',
    creditLimit: '学分限制',
    choiceLimit: '选择限制',
    enterLimit: '请输入限制数量',
    optionError: '请至少选择一个选项',
    topicError: '请至少添加一个课题',
    courseDetail: '(课程周期：{duration}; 学分：{credit}学分)',
    moveUp: '上移',
    moveDown: '下移',
    saveAndPublish: '保存并发布',
    questPreview: '预览问卷',
    questContent: '问卷内容',
    creditNeedTips: '需选择{credit}学分',
    optionNeedTips: '可选择{option}个选项',
    compulsoryTips: '选修课类型可以添加备选课程，用于第一选择无法满足时的备选',
    alternative: '备选选项',
    unpublished: '未发布',
    DNS: '未开始',
    ended: '已结束',
    processing: '进行中',
    setTemplate: '设置选课模板',
    applyTemplate: '应用选课模板',
    viewUnmet: '查看未满足条件的选课',
    status: '选课状态',
    submitted: '已提交',
    unsubmitted: '未提交',
    selectionDetail: '选课详情',
    schoolYearCourse: '{year}课程',
    selectedCredits: '已选学分：必修：{compulsory} 选修：{elective}',
    totalCredits: '总学分：{total}',
    teacherModifyTips: '此为教师修改后的选课结果',
    viewStudentSelection: '查看学生提交的选课',
    notPassTips: '未通过"{course}"课程',
    notSelectTips: '未选择"{course}"课程',
    editResult: '修改选课结果',
    studentSelection: '学生提交的选课',
    finalScore: '最终分数',
    earnedCredits: '获得学分',
    selectedCredit: '已选学分',
    selectedCredit2: '必修：{compulsory} 选修：{elective}',
    creditRequire: '学分要求',
    totalCreditMax: '最大总学分: {credit}',
    totalCreditMin: '最小总学分: {credit}',
    addCourse: '添加选课',
    emptyCourseTips: '学生未提交选课结果，您可给学生应用选课模版或添加选课',
    templateTips:
      '选课模板设置后，会应用到未提交选课的学生，若学生在设置选课模板后提交选课结果，此模板不会继续应用到该学生',
    notice: '提示',
    courseLevel: '年级',
    selectNum: '选课人数',
    courseCapacity: '课堂容量',
    recommendedQuantity: '建议Section数量',
    selectionTable: '选课学生表',
    teacherModify: '教师修改选课',
    studentSelect: '学生原提交选课',
    deleteConfirm: '确认删除该问卷?',
    semesters: '1学期 | {count}学期',
    schoolYears: '1学年 | {count}学年',
    submit: '提交问卷',
    statusTips: {
      DNS: '选课未开始! 到达选课时间才可提交选课结果.',
      ended: '选课已结束! 不可再提交选课结果.',
    },
    warningTips: '选课未满足学分要求，请修改后提交',
    totalCredit: '总学分',
    mutexGroup: '互斥组',
    topicDes: '选题说明',
  },
  address: {
    campusAddress: '校园通讯录',
    personalAddress: '个人通讯录',
    1251: '主班级',
    1253: '学院',
    1255: '课程班级',
    1257: '宿舍',
    1256: '自习室',
    1258: '延展课程',
    email: '邮箱',
    mobile: '电话',
    copy: '复制',
    exterPerson: '外部人员',
    copySuccess: '复制成功!',
  },
  announcement: {
    all: '全部',
    campusActivity: '校园活动',
    campusPolicy: '校园政策',
    attention: '注意事项',
    search: '搜索',
    edit: '通知在{time}曾被编辑',
    department: '部门',
    time: '时间',
    status: '状态',
    publisher: '发布人',
    isTop: '是否置顶',
    topEndTime: '置顶结束时间',
    published: '已发布',
    unpublished: '未发布',
    yes: '是',
    no: '否',
    keyword: '关键字',
    date: '日期',
    tag: '标签',
  },
  graduation: {
    graduationRqm: '毕业标准',
    progress: '毕业进度',
    compulsoryCredit: '必修学分',
    electiveCredit: '选修学分',
    finishedCredit: '已修学分',
    finished: '已修课程',
    inProgress: '进行中课程',
    courseAdmission: '课程条件{index}',
    elective: '选修课程',
    gotCredits: '获得学分',
    course: '课程',
    remark: '备注',
    credit: '学分',
    viewRequirements: '查看毕业标准',
    totalCredit: '总学分',
    compulsoryLabel: '必修课程 (学分: {credit})',
    mark: '需要学分',
    courseGroup: '课程组{index}',
    electiveLabel: '选修课程 (学分: {credit})',
    maxRecognizedCreitds: '最大承认学分',
    EXTRA: '额外课程',
    OVERFLOW: '超出必修条件，计入选修',
  },
}






































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  setToken,
  setLocale,
  setUserAuth,
  getToken,
  getSchoolList,
  getSchoolInfo,
  removeToken,
  // setFirstLogin,
  // removeFirstLogin,
  excludeSpecial,
} from '@/utils/utils'
import { LoginController } from '@/services/request.service'
import SetPasswordModal from './components/SetPasswordModal.vue'
import SetMailModal from './components/SetMailModal.vue'

@Component({
  components: {
    SetPasswordModal,
    SetMailModal,
  },
})
export default class Login extends Vue {
  private passwordLoginForm: any
  private loginLoading = false
  private setModalShow = false
  // private setMailModalShow = false
  private setPassWordType = ''
  private email = ''
  private get locale(): string {
    return this.$store.state.locale
  }

  private get school(): any {
    const school = getSchoolInfo()
    if (school && Object.keys(school).length) {
      return school
    } else {
      return {
        name: '',
        enName: '',
      }
    }
  }

  private beforeCreate(): void {
    this.passwordLoginForm = this.$form.createForm(this)
  }

  private changeLocale(language): void {
    const currentLocale = this.$i18n.locale
    if (currentLocale === 'zh' && language !== 'zh') {
      this.$i18n.locale = 'en'
      this.$store.commit('changeLocale', 'en')
      setLocale('en')
    } else if (currentLocale === 'en' && language !== 'en') {
      this.$i18n.locale = 'zh'
      this.$store.commit('changeLocale', 'zh')
      setLocale('zh')
    }
  }

  private submit(): void {
    this.loginLoading = true
    const form = this.passwordLoginForm
    form.validateFields((err: any, values: any) => {
      if (!err) {
        const { ...data } = values
        const account = excludeSpecial(values.accountNo)
        const password = excludeSpecial(values.password)
        LoginController.login({ accountNo: account, password })
          .then(res => {
            if (res.data.success) {
              const token = res.data.token
              setToken(token, true)
              if (res.data.firstLogin && !res.data.email) {
                // this.setMailModalShow = true
                this.email = res.data.email
                // setFirstLogin(true)
              } else {
                this.$route.query.toPath
                  ? (window.location.href = window.location.origin + this.$route.query.toPath)
                  : window.location.reload()
              }
            } else {
              const accountNo = form.getFieldValue('accountNo')
              form.setFields({
                ['accountNo']: {
                  value: accountNo,
                  errors: [
                    { message: this.$tc(`errorCode.${res.data.resCode}`), field: 'accountNo' },
                  ],
                },
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.loginLoading = false
          })
      } else {
        this.loginLoading = false
      }
    })
  }

  private setPasswordSuccess(params): void {
    const token = params.token
    setToken(token, true)
    if (params.firstLogin && !params.email) {
      // this.setMailModalShow = true
      this.email = params.email
      // setFirstLogin(true)
    } else {
      window.location.reload()
    }
  }

  // private setMailSuccess(): void {
  //   removeFirstLogin()
  //   window.location.reload()
  // }

  private resetPwd(): void {
    this.setModalShow = true
    this.setPassWordType = 'forgetPassword'
  }
}

















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { RouteRecord } from 'vue-router'
import { getSchoolInfo } from '@/utils/utils'
import { whiteList } from '@/router'
import { differSchoolId } from '@/constant/config'

@Component({})
export default class PCMenu extends Vue {
  private menuKeys: Array<string> = []
  private menuOpenKeys: Array<string> = []
  private collapsed = false

  private get locale(): string {
    return this.$store.state.locale
  }

  private get unreadStatus(): any {
    return this.$store.state.unreadStatus
  }

  private get unfinishedStatus(): any {
    return this.$store.state.unfinishedStatus
  }

  private get unReadTeach(): any {
    return this.$store.state.unReadTeach
  }

  private get subRoutes(): any {
    return this.$store.state.routes[0].children.filter(child => !child.hidden)
  }

  private get userAuths(): any {
    return this.$store.state.routeAuths
  }

  private get realMenus(): any {
    let allMenu = [
      {
        name: 'announcement',
      },
      {
        name: 'studentDetail',
      },
      {
        name: 'curriculum',
      },
      // {
      //   name: 'courseMaterial'
      // },
      // differSchoolId.includes(this.schoolId) ? {
      //   name: "assignment",
      //   showDot: this.unfinishedStatus
      // } : {
      //   name: "homework",
      //   showDot: this.unfinishedStatus
      // },
      {
        name: 'teaching',
        showDot: !this.unReadTeach,
      },
      {
        name: 'points',
      },
      {
        name: 'diary',
      },
      // {
      //   name: "moment",
      // },
      {
        name: 'report',
      },
      {
        name: 'attendance',
      },
      {
        name: 'message',
        showDot: this.unreadStatus,
        children: [
          {
            name: 'inbox',
            showDot: this.unreadStatus,
          },
          {
            name: 'outbox',
          },
        ],
      },
      {
        name: 'addressBook',
        children: [
          {
            name: 'schoolAddressBook',
          },
          {
            name: 'privateAddressBook',
          },
        ],
      },
      {
        name: 'CCAClass',
      },
      {
        name: 'calendar',
      },
    ] as any
    let menuList = allMenu.filter(
      menu =>
        this.userAuths.includes(menu.name) ||
        (this.userAuths.includes(menu.redirect) && menu.redirect) ||
        whiteList.includes(menu.name)
    )
    menuList.forEach(menu => {
      let specialMenu = ['message']
      if (specialMenu.includes(menu.name)) {
        switch (menu.name) {
          case 'message':
            menu.children = [
              {
                name: 'inbox',
                showDot: this.unreadStatus,
              },
              {
                name: 'outbox',
              },
            ]
            break
        }
      } else {
        menu.children = (menu.children || []).filter(
          childMenu =>
            this.userAuths.includes(childMenu.name) ||
            (childMenu.redirect && this.userAuths.includes(childMenu.redirect))
        )
      }
    })
    return menuList
  }

  private get routes(): Array<any> {
    const router = this.$route as any
    return router.matched.filter((item: RouteRecord) => item.meta && item.meta.routerName)
  }

  private get schoolId(): number {
    return getSchoolInfo().schoolId
  }

  private get schoolInfo(): any {
    return getSchoolInfo()
  }

  private get menus(): Array<object> {
    return this.subRoutes
  }

  private get rootMenuOpenKeys(): Array<string> {
    const routes = Object.values(this.subRoutes) as any
    return routes.map(route => route.name)
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(): void {
    const routes = this.routes
    this.menuOpenKeys = routes[2]
      ? [routes[1].name, routes[0].name]
      : routes[1]
      ? [routes[0].name]
      : []
    this.menuKeys = routes[2]
      ? [routes[2].name, routes[1].name, routes[0].name]
      : routes[1]
      ? [routes[1].name, routes[0].name]
      : [routes[0].name]
  }

  private onOpenChange(openKeys): void {
    const latestOpenKey = openKeys.find(key => this.menuOpenKeys.indexOf(key) === -1)
    if (this.rootMenuOpenKeys.indexOf(latestOpenKey) === -1) {
      this.menuOpenKeys = openKeys
    } else {
      const children = this.subRoutes.filter(route => route.name === latestOpenKey)[0].children
      const childrenArray = children.map(child => child.name)
      this.menuOpenKeys = latestOpenKey ? [latestOpenKey, ...childrenArray] : []
    }
  }

  private clickLeftMenu({ key }: { key: string }): void {
    this.$router.push({ name: key }).catch(err => {})
  }

  private calcType(name): string {
    const nameTypeList = {
      studentDetail: 'idcard',
      homework: 'read',
      diary: 'database',
      attendance: 'carry-out',
      calendar: 'calendar',
      message: 'mail',
      points: 'trophy',
      report: 'book',
      courseMaterial: 'file-zip',
      CCAClass: 'appstore',
      moment: 'copyright-circle',
      assignment: 'snippets',
      teaching: 'snippets',
      addressBook: 'index-address-book-icon',
      announcement: 'notification',
      curriculum: 'profile',
    }
    return nameTypeList[name]
  }

  private getRealName(route): string {
    if (route.meta.duplicateName) {
      return route.meta.realName
    }
    return route.meta.routerName
  }
}

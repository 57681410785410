import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './modules/types'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    locale: 'zh',
    username: '',
    memberId: 0,
    fileUploadProgress: {},
    forceBack: false,
    auths: [],
    routes: [],
    currentStudent: {},
    unreadStatus: false,
    unfinishedStatus: false,
    unReadTeach: false,
    taskDot: {},
    systemMessageNum: {},
    currentSchoolYear: {},
    routeAuths: {},
    authLoaded: false,
    userInfo: {},
    CSInfo: {},
    ratio: 1,
    messageIds: [],
  },
  getters: {
    getProgress: state => {
      return state.fileUploadProgress
    },
  },
  mutations: {
    setAllMessageIds(state, ids) {
      state.messageIds = ids
    },
    changeLocale(state, payload: string) {
      state.locale = payload
    },
    setUserInfo(state, userInfo) {
      const { username, memberId } = userInfo
      state.username = username
      state.memberId = memberId
      state.userInfo = userInfo
    },
    setCurriculumStudentInfo(state, info) {
      state.CSInfo = info
    },
    setCurrentSchoolYear(state, payload) {
      state.currentSchoolYear = payload
    },
    setFileUploadProgress(state, payload: { key: string; progress: number }) {
      const { key, progress } = payload
      Vue.set(state.fileUploadProgress, key, progress)
    },
    changeForceBack(state, payload: boolean) {
      state.forceBack = payload
    },
    setSystemMessageNum(state, systemMessageNum) {
      state.systemMessageNum = systemMessageNum
    },
    setAuths(state, payload) {
      state.auths = payload
    },
    setUnreadStatus(state, status) {
      state.unreadStatus = status
    },
    setTeachStatus(state, status) {
      state.unReadTeach = status
    },
    setUnfinishedStatus(state, status) {
      state.unfinishedStatus = status
    },
    setTaskDot(state, dot) {
      state.taskDot = dot
    },
    setRoutes(state, payload) {
      state.routes = payload
    },
    removeAuths(state) {
      state.auths = []
    },
    setCurrentStudent(state, payload) {
      state.currentStudent = payload
    },
    setRouteAuths(state, payload) {
      state.routeAuths = payload
    },
    setAuthLoadStatus(state, status) {
      state.authLoaded = status
    },
    setRatio(state, ratio) {
      state.ratio = ratio
    },
  },
  actions: {
    refreshRoutes({ commit }) {
      commit('removeAuths')
      commit('setAuthLoadStatus', false)
    },
  },
}

export default new Vuex.Store<RootState>(store)

/* eslint-disable */
import fetch from '../utils/fetch';
import * as M from './__model';
import {AxiosRequestConfig} from 'axios';
export class HomeworkController {

    /**
      * 获取作业详情 
      */ 
    public static detail(homeworkStudentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.HomeworkResponse>(`/homework/detail`, { params:{homeworkStudentId},...config});
    }

    /**
      * 获取作业列表 
      */ 
    public static getList(request: M.HomeworkPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.HomeworkPageResponse>>(`/homework/list`, { params:request,...config});
    }

    /**
      * 提交作业 
      */ 
    public static handIn(request: M.HandInRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/homework/handIn`,request, config);
    }

    /**
      * 查询是否有未完成作业, 返回未完成作业类型列表 
      */ 
    public static hasUnHandIn(config?: AxiosRequestConfig ) {
      return fetch.get<string[]>(`/homework/hasUnHandIn`,config);
    }

    /**
      * 保存作业 
      */ 
    public static save(request: M.HandInRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/homework/save`,request, config);
    }


}
export class MessageController {

    /**
      * 删除发件消息 
      */ 
    public static deleteFrom(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/message/deleteFrom`, { params:{messageMasterId},...config});
    }

    /**
      * 删除收件消息 
      */ 
    public static deleteTo(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/message/deleteTo`, { params:{messageId},...config});
    }

    /**
      * 获取发送消息详情 
      */ 
    public static getFromDetail(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageDetailResponse[]>(`/message/fromDetail`, { params:{messageMasterId},...config});
    }

    /**
      * 获取发件箱列表 
      */ 
    public static getFromList(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.FromListResponse>>(`/message/fromList`, { params:request,...config});
    }

    /**
      * 查询读取情况 
      */ 
    public static getReadInfo(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageReadResponse>(`/message/readInfo`, { params:{messageMasterId},...config});
    }

    /**
      * 获取接收消息详情 
      */ 
    public static getToDetail(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageDetailResponse[]>(`/message/toDetail`, { params:{messageId},...config});
    }

    /**
      * 获取收件箱列表 
      */ 
    public static getToList(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ToListResponse>>(`/message/toList`, { params:request,...config});
    }

    /**
      * 查询是否有未读消息 
      */ 
    public static hasUnread(config?: AxiosRequestConfig ) {
      return fetch.get<boolean>(`/message/hasUnread`,config);
    }

    /**
      * 查询未读系统消息数量 
      */ 
    public static hasUnreadSystem(config?: AxiosRequestConfig ) {
      return fetch.get<number>(`/message/hasUnreadSystem`,config);
    }

    /**
      * 发送 
      */ 
    public static sendMessage(request: M.MessageSaveRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/message/send`,request, config);
    }

    /**
      * 设置消息已读 
      */ 
    public static setRead(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/message/setRead`, {}, { params:{messageId, },...config});
    }

    /**
      * 查询最新系统通知 
      */ 
    public static sysMessageNotice(config?: AxiosRequestConfig ) {
      return fetch.get<M.SysMessageNoticeResponse>(`/message/sys-message-notice`,config);
    }

    /**
      * 查询指定时间段最新未读消息 
      */ 
    public static unReadMessageCount(minute: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/message/unread-message-count`, { params:{minute},...config});
    }

    /**
      * 撤回 
      */ 
    public static withdraw(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.put<boolean>(`/message/withdraw`, {}, { params:{messageMasterId, },...config});
    }


}
export class ExamController {

    /**
      * 获取考试下拉框 
      */ 
    public static getExamDropdown(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/exam/dropdown`, { params:{schoolYearId},...config});
    }

    /**
      * 获取成绩单详情 
      */ 
    public static getTranscript(examId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTranscriptResponse>(`/exam/transcript`, { params:{examId},...config});
    }


}
export class CommonController {

    /**
      * 得到七牛Token 
      */ 
    public static getQiniuEtcToken(config?: AxiosRequestConfig ) {
      return fetch.get<M.TokenResponse>(`/common/qiniuToken`,config);
    }

    /**
      * 得到七牛视频转码Token 
      */ 
    public static getQiniuForVideo(config?: AxiosRequestConfig ) {
      return fetch.get<M.TokenResponse>(`/common/qiniuTokenForVideo`,config);
    }

    /**
      * 保存资源信息 
      */ 
    public static saveResource(request: M.ResourceRequest, config?: AxiosRequestConfig ) {
      return fetch.post<number>(`/common/saveResource`,request, config);
    }

    /**
      * 通用翻译接口 
      */ 
    public static translate(src: string, config?: AxiosRequestConfig ) {
      return fetch.post<M.StringResponse>(`/common/translate`,src, config);
    }


}
export class SemesterController {

    /**
      * 获取当前学年 
      */ 
    public static getCurrentSchoolYear(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearResponse>(`/semester/currentSchoolYear`,config);
    }


}
export class AddressBookController {

    /**
      * 分页根据部门查询通讯录列表 
      */ 
    public static addressBookList(request: M.AddressBookRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DepPersonResponse>>(`/address-book/dep-person`, { params:request,...config});
    }

    /**
      * 根据部门id查询部门信息 
      */ 
    public static departmentByDepId(depId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DepDetailResponse>(`/address-book/department/byDepId`, { params:{depId},...config});
    }

    /**
      * 查询部门列表 
      */ 
    public static departmentList(request: M.DepQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.DepartmentResponse[]>(`/address-book/department`, { params:request,...config});
    }

    /**
      * 个人通讯录 
      */ 
    public static personalBook(name?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.PersonalBookResponse[]>(`/address-book/personal`, { params:{name},...config});
    }


}
export class NoticeController {

    /**
      * 部门下拉框数据 
      */ 
    public static departsDropDown(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/notice/depart`,config);
    }

    /**
      * 获取公告详情 
      */ 
    public static getNotice(noticeId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.NoticeResponse>(`/notice/${noticeId}`, { params:{},...config});
    }

    /**
      * 个人公告列表接口 
      */ 
    public static getNoticePersonal(query: M.NoticeQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ReadInfoNoticeItemResponse>>(`/notice/personal`, { params:query,...config});
    }

    /**
      * 设置已读 
      */ 
    public static setRead(noticeId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/notice/${noticeId}/read`, {}, { params:{},...config});
    }

    /**
      * tag下拉 
      */ 
    public static tagDropdown(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/notice/tag`,config);
    }


}
export class DiaryController {

    /**
      * 获取日记簿记录详情 
      */ 
    public static getDiaryEntry(diaryEntryId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DiaryEntryResponse>(`/diary/`, { params:{diaryEntryId},...config});
    }

    /**
      * 获取学生日记簿详情 
      */ 
    public static getDiaryEntrySummary(pageCurrent: number, pageSize: number, primaryTypeId?: number, diaryEntryTypeId?: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DiaryEntryItemResponse>>(`/diary/by-student`, { params:{pageCurrent,pageSize,primaryTypeId,diaryEntryTypeId,start,end},...config});
    }

    /**
      * 获取日记簿子类型 
      */ 
    public static getDiaryEntryTypes(primaryTypeId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/diary/entry-type`, { params:{primaryTypeId},...config});
    }

    /**
      * 获取日记簿记录类型 
      */ 
    public static getPrimaryTypes(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/diary/primary-type`,config);
    }

    /**
      * 获取日记簿学生分数 
      */ 
    public static getStudentDiaryInfo(config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDiaryInfoResponse>(`/diary/student-info`,config);
    }


}
export class CSQController {

    /**
      * 获取问卷详情 
      */ 
    public static getCSQById(csqId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CSQResponse>(`/csq`, { params:{csqId},...config});
    }

    /**
      * 获取学生选课详情 
      */ 
    public static getStudentCSQ(csqId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentCSQResponse>(`/csq/byStudent`, { params:{csqId},...config});
    }

    /**
      * 老师填写选课问卷 
      */ 
    public static saveCSQFill(request: M.CSQFillRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/csq`,request, config);
    }


}
export class CCACurriculumController {

    /**
      * 报名 
      */ 
    public static apply(request: M.ApplyBatchRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ApplyBatchResultResponse[]>(`/cca/curriculum/apply`,request, config);
    }

    /**
      * 取消报名 
      */ 
    public static cancelApply(courseId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/cca/curriculum/cancelApply`, {}, { params:{courseId, },...config});
    }

    /**
      * 查询课程详情 
      */ 
    public static detail(courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CCACurriculumResponse>(`/cca/curriculum/detail`, { params:{courseId},...config});
    }

    /**
      * 列表查询 
      */ 
    public static list(request: M.CCACurriculumPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.CCACurriculumWeeklyListResponse[]>(`/cca/curriculum/list`, { params:request,...config});
    }


}
export class ClassMaterialController {

    /**
      * 获取课程资料详情 
      */ 
    public static detail(classMaterialId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassMaterialResponse>(`/class/material/detail`, { params:{classMaterialId},...config});
    }

    /**
      * 获取课程资料列表 
      */ 
    public static getDetailList(request: M.ClassMaterialDetailPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ClassMaterialDetailPageResponse>>(`/class/material/detailList`, { params:request,...config});
    }

    /**
      * 获取课程资料列表 
      */ 
    public static getList(request: M.ClassMaterialPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ClassMaterialPageResponse>>(`/class/material/list`, { params:request,...config});
    }


}
export class GraduationController {

    /**
      * 获取毕业标准详情 
      */ 
    public static graduationDetail(settingId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GraduationStandardResponse>(`/${settingId}`, { params:{},...config});
    }

    /**
      * 毕业进度 
      */ 
    public static graduationSchedule(config?: AxiosRequestConfig ) {
      return fetch.get<M.ScheduleResponse>(`/schedule`,config);
    }

    /**
      * 查询学生毕业标准信息 
      */ 
    public static studentStandard(config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentStandardInfoResponse>(`/standard`,config);
    }


}
export class GradeController {

    /**
      * 全部报告接口 
      */ 
    public static getAllGradeReports(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearReportResponse[]>(`/grade/grade-report/by-student/all`,config);
    }

    /**
      * 查询评分标准详情 
      */ 
    public static getGradeLevelDetail(type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeLevelResponse[]>(`/grade/gradeLevel/detail`, { params:{type},...config});
    }

    /**
      * 获取评估报告详情 
      */ 
    public static getGradeReportDetail(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeReportResponse>(`/grade/grade-report`, { params:{gradePeriodId},...config});
    }

    /**
      * 获取学生评估数据列表 
      */ 
    public static getGradeReports(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeReportItemResponse[]>(`/grade/grade-report/by-student`, { params:{schoolYearId},...config});
    }

    /**
      * 初中年度报告 
      */ 
    public static getMidSchoolAnnualReport(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MidSchoolAnnualReportResponse>(`/grade/annual-report/mid`, { params:{gradePeriodId},...config});
    }

    /**
      * 中学报告详情 
      */ 
    public static getMidSchoolReport(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MidSchoolReportDetailResponse>(`/grade/mid-school/grade-report/detail`, { params:{gradePeriodId},...config});
    }

    /**
      * 获取报告详情 
      */ 
    public static getMonthlyReport(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyReportDetailResponse>(`/grade/report/${gradePeriodId}/${studentId}`, { params:{},...config});
    }

    /**
      * 接收教师发送的报告 
      */ 
    public static getMonthlyReportsByStudent(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyReportResponse[]>(`/grade/monthly-grade/by-student`, { params:{schoolYearId},...config});
    }

    /**
      * 高中年度报告 
      */ 
    public static getUpperSchoolAnnualReport(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.UpperSchoolAnnualReportResponse>(`/grade/annual-report/upper`, { params:{gradePeriodId},...config});
    }

    /**
      * 高中报告详情 
      */ 
    public static getUpperSchoolReport(gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.UpperSchoolReportDetailResponse>(`/grade/upper-school/grade-report/detail`, { params:{gradePeriodId},...config});
    }


}
export class DropDownController {

    /**
      * 获取学生关联课程 
      */ 
    public static getRelatedAllCourses(request: M.RelatedCourseRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.TaskCourseResponse[]>(`/dropDown/relatedAllCourses`, { params:request,...config});
    }

    /**
      * 获取学生关联cca课程 
      */ 
    public static getRelatedCcaCourses(schoolYearId: number, deleteFlag: boolean, courseFlag?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/relatedCcaCourses`, { params:{schoolYearId,deleteFlag,courseFlag},...config});
    }

    /**
      * 获取学生关联普通课程级联 
      */ 
    public static getRelatedCourses(schoolYearId: number, deleteFlag: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/dropDown/relatedCourses`, { params:{schoolYearId,deleteFlag},...config});
    }

    /**
      * 获取全部学年列表 
      */ 
    public static getSchoolYearList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/schoolYearList`,config);
    }

    /**
      * 获取全部学年列表按 当前-未来-历史 
      */ 
    public static getSchoolYearRuleList(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearDropDownResponse[]>(`/dropDown/schoolYearRuleList`,config);
    }

    /**
      * 获取年级列表 
      */ 
    public static getSectionList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/sectionList`,config);
    }

    /**
      * 根据年级id列表 获取科目列表（并集） 
      */ 
    public static getSubjectBySectionsUnion(request: M.SectionIdsRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/subjectsBySectionUnion`, { params:request,...config});
    }

    /**
      * 获取当前和未来学年列表 
      */ 
    public static getUnPassedSchoolYearList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/unPassedSchoolYearList`,config);
    }

    /**
      * 查询是否含未读教学课程 
      */ 
    public static unReadTeach(config?: AxiosRequestConfig ) {
      return fetch.get<boolean>(`/dropDown/un-read-teach`,config);
    }


}
export class CalendarController {

    /**
      * 根据月份查询校历 
      */ 
    public static getCalendarByMonth(dateStart: number, dateEnd: number, date: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CalendarResponse>(`/calendar/byMonth`, { params:{dateStart,dateEnd,date},...config});
    }

    /**
      * 查询事件信息 
      */ 
    public static getEventInfo(eventId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.EventResponse>(`/calendar/getEventInfo`, { params:{eventId},...config});
    }


}
export class StudentController {

    /**
      * 班级信息 
      */ 
    public static classInfo(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassInfoResponse[]>(`/student/class-info`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 查询学生详细信息 
      */ 
    public static detail(config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDetailResponse>(`/student/detail`,config);
    }

    /**
      * 查询学生课程信息信息 
      */ 
    public static getCourseInfo(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentCourseResponse[]>(`/student/course-info`, { params:{schoolYearId},...config});
    }

    /**
      * 查询学生家长账户信息 
      */ 
    public static getParents(config?: AxiosRequestConfig ) {
      return fetch.get<M.ParentResponse[]>(`/student/getParents`,config);
    }

    /**
      * 查询学生说明信息 
      */ 
    public static getRemarks(config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentRemarkResponse>(`/student/getRemarks`,config);
    }

    /**
      * 获取学生简要信息 
      */ 
    public static getStudentBriefInfo(config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentBriefDetailResponse>(`/student/studentBriefInfo`,config);
    }

    /**
      * 获取转学信息 
      */ 
    public static getStudentTransInfo(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTransInfoResponse>(`/student/trans-info/${studentId}`, { params:{},...config});
    }

    /**
      * 查询学生教师信息 
      */ 
    public static getTeachersByStudent(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeachersAndTypeResponse[]>(`/student/teachers`,config);
    }

    /**
      * 获取用户详情 
      */ 
    public static getUserInfo(config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentResponse>(`/student/userInfo`,config);
    }

    /**
      * 个人中心-修改密码 
      */ 
    public static updatePassword(request: M.PasswordRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ResultResponse>(`/student/updatePassword`,request, config);
    }

    /**
      * 更新用户详情 
      */ 
    public static updateUserInfo(request: M.StudentRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ResultResponse>(`/student/updateUserInfo`,request, config);
    }


}
export class MomentsController {

    /**
      * 学生端点赞 
      */ 
    public static favor(favor: boolean, momentId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/moments/favor`, {}, { params:{favor, momentId, },...config});
    }

    /**
      * 学生端查询班级圈 
      */ 
    public static getMoments(pageCurrent: number, pageSize: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.MomentResponse>>(`/moments`, { params:{pageCurrent,pageSize,start,end},...config});
    }


}
export class TaskController {

    /**
      * 红点一键已读 
      */ 
    public static allRead(config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/task/read/true`,config);
    }

    /**
      * 获取作业详情 
      */ 
    public static detail(taskStudentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TaskResponse>(`/task/detail`, { params:{taskStudentId},...config});
    }

    /**
      * 获取任务列表 
      */ 
    public static getList(request: M.TaskPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.TaskPageResponse>>(`/task/list`, { params:request,...config});
    }

    /**
      * 获取教学课程信息列表 
      */ 
    public static getMergeList(request: M.TeachingPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.TeachingPageResponse>>(`/task/mergeList`, { params:request,...config});
    }

    /**
      * 提交任务 
      */ 
    public static handIn(request: M.TaskStudentRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/task/handIn`,request, config);
    }

    /**
      * 是否有未完成作业 
      */ 
    public static hasUnHandIn(config?: AxiosRequestConfig ) {
      return fetch.get<boolean>(`/task/hasUnHandIn`,config);
    }

    /**
      * 保存作业 
      */ 
    public static save(request: M.TaskStudentRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/task/save`,request, config);
    }


}
export class TeacherController {

    /**
      * 查询学生关联教师 
      */ 
    public static getRelatedTeachers(config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherRelationResponse[]>(`/teacher/relatedTeachers`,config);
    }


}
export class CurriculumController {

    /**
      * 获取周课表(学生) 
      */ 
    public static getClassArrangesByStudentAndWeek(schoolYearId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentWeeklyCurriculumResponse>(`/curriculum/student/${schoolYearId}`, { params:{start,end},...config});
    }


}
export class LoginController {

    /**
      * 获取所有学校列表 
      */ 
    public static getAllSchools(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolBriefResponse[]>(`/login/schools`,config);
    }

    /**
      * 根据域名查询学校信息 
      */ 
    public static getSchoolInfo(baseUrl: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolBriefResponse>(`/login/schoolInfo`, { params:{baseUrl},...config});
    }

    /**
      * 登录接口 
      */ 
    public static login(request: M.LoginRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.LoginResponse>(`/login/`,request, config);
    }

    /**
      * 忘记密码-重置密码 
      */ 
    public static resetPassword(request: M.ForgotPasswordRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.LoginResponse>(`/login/resetPassword`,request, config);
    }

    /**
      * 发送验证码 
      */ 
    public static sendVerifyCode(studentNum: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.ResultResponse>(`/login/vcodeSend`, { params:{studentNum},...config});
    }


}
export class AttendanceController {

    /**
      * 获取考勤类型 
      */ 
    public static getAttendanceType(config?: AxiosRequestConfig ) {
      return fetch.get<M.StringResponse>(`/attendance/attendance-type`,config);
    }

    /**
      * 学生个人考勤(小学) 
      */ 
    public static getEleStudentStatistics(schoolYearId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.EleDailyAttendanceResponse[]>(`/attendance/statistic/student/ele/${schoolYearId}`, { params:{start,end},...config});
    }

    /**
      * 幼儿园获取学生每月考勤数据 
      */ 
    public static getKGStudentAttendance(date: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/attendance/kg/student`, { params:{date},...config});
    }

    /**
      * 课节考勤统计 
      */ 
    public static getStudentCourseStatistics(start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/course/student`, { params:{start,end},...config});
    }

    /**
      * 学生个人考勤 
      */ 
    public static getStudentStatistics(schoolYearId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDailyAttendanceResponse[]>(`/attendance/statistic/student/${schoolYearId}`, { params:{start,end},...config});
    }


}
export class HonorsAndLeaderShipController {

    /**
      * 获取honor详情 
      */ 
    public static getHonor(honorId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.HonorResponse>(`/honors-and-leadership/honor`, { params:{honorId},...config});
    }

    /**
      * 获取honor类型下拉 
      */ 
    public static getHonorTypes(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/honors-and-leadership/honor/type`,config);
    }

    /**
      * 获取honor列表 
      */ 
    public static getHonors(pageCurrent: number, pageSize: number, honorTypeId?: number, sectionId?: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.HonorItemResponse>>(`/honors-and-leadership/honors`, { params:{pageCurrent,pageSize,honorTypeId,sectionId,start,end},...config});
    }

    /**
      * 获取leadership详情 
      */ 
    public static getLeaderShip(leadershipId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.LeaderShipResponse>(`/honors-and-leadership/leadership`, { params:{leadershipId},...config});
    }

    /**
      * 获取leadership列表 
      */ 
    public static getLeaderShipByStudent(pageCurrent: number, pageSize: number, type?: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.LeaderShipItemResponse>>(`/honors-and-leadership/leaderships`, { params:{pageCurrent,pageSize,type,start,end},...config});
    }

    /**
      * 获取leadership类型 
      */ 
    public static getLeadershipTypes(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/honors-and-leadership/leadership/type`,config);
    }


}
export class TaskGradeController {

    /**
      * 获取学生有评分报告发送的学期 
      */ 
    public static getGradingSemesters(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.SemesterResponse[]>(`/task-grade/semester`, { params:{schoolYearId},...config});
    }

    /**
      * 获取报告详情 
      */ 
    public static getReportDetail(reportId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ReportDetailResponse>(`/task-grade/report/detail`, { params:{reportId},...config});
    }

    /**
      * 获取学生成绩册 
      */ 
    public static getStudentGradeBook(schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeBookResponse>(`/task-grade/grade-book`, { params:{schoolYearId},...config});
    }

    /**
      * 学生详情成绩 
      */ 
    public static getStudentGradeSummary(semesterId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentGradeSummaryResponse>(`/task-grade/student`, { params:{semesterId},...config});
    }


}

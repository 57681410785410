























import { getGuideViewStatus, setGuideViewStatus } from '@/utils/utils'
import { Vue, Component, Prop, Emit, Model } from 'vue-property-decorator'

@Component
export default class Guide extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly type!: any
  private current: any = 1

  private get stepMax(): any {
    return this.type === 'teaching' ? 6 : 9
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get ratio(): any {
    return this.$store.state.ratio
  }

  private next(): void {
    if (this.current < this.stepMax) {
      this.current += 1
    } else {
      let status: any = getGuideViewStatus()
      status[this.type] = true
      setGuideViewStatus(status)
      this.closeModal()
    }
  }
  private last(): void {
    if (this.current > 1) this.current -= 1
  }

  @Emit('change')
  private closeModal(): boolean {
    this.current = 1
    return false
  }
}



































































































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import { CurriculumController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import flattenDeep from 'lodash/flattenDeep'

@Component
export default class MyCurriculum extends Vue {
  @Prop({ default: {} }) public readonly curriculumData!: any
  @Prop({ default: {} }) public readonly studentInfo!: any
  @Prop() public readonly startTime!: any
  @Prop() public readonly endTime!: any
  @Prop() public readonly yearKey!: number
  @Prop() public readonly yearList!: Array<any>
  private substitute = 0
  private courses: any = {}

  private moment = moment

  private get week(): Array<any> {
    const classArranges = this.classArranges
    return [
      {
        name: '周一',
        enName: 'Mon',
        dayOfWeek: 1,
      },
      {
        name: '周二',
        enName: 'Tue',
        dayOfWeek: 2,
      },
      {
        name: '周三',
        enName: 'Wed',
        dayOfWeek: 3,
      },
      {
        name: '周四',
        enName: 'Thu',
        dayOfWeek: 4,
      },
      {
        name: '周五',
        enName: 'Fri',
        dayOfWeek: 5,
      },
    ].map(item => {
      if (classArranges && classArranges[item.dayOfWeek]) {
        return {
          ...item,
          ...classArranges[item.dayOfWeek],
        }
      } else {
        return item
      }
    })
  }

  private get classArranges(): Array<any> {
    return this.curriculumData.classArranges || []
  }

  private get periods(): Array<any> {
    return this.curriculumData.classPeriods || []
  }

  private get total(): any {
    return this.curriculumData.total
  }
  // private created(): void {
  //   this.getMyCurriculum()
  // }

  private calcBg(course): string {
    let newColor = '#fff'
    if (!course) return newColor
    let color = (course.courseId.subject || {}).color
    if (color) {
      const r = parseInt(color.slice(1, 3), 16)
      const g = parseInt(color.slice(3, 5), 16)
      const b = parseInt(color.slice(5, 7), 16)
      newColor = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + '0.48)'
    }
    if (course.type === '1002') {
      return 'rgba(70,194,154,0.2)'
    } else if (course.type === 'periodArranges') {
      return 'rgba(128,128,154,0.04)'
    } else {
      return newColor
    }
  }

  private getTeachersName(course): any {
    return course ? course.teachers.map(item => item.name).join('、') : ''
  }
}

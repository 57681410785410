





























import { Component, Vue } from 'vue-property-decorator'
import { RouteRecord } from 'vue-router'

@Component
export default class MenuLevel3 extends Vue {
  private newMessage = 0

  private get subRoutes(): any {
    return this.$store.state.routes[0].children.filter(child => !child.hidden)
  }

  private get routes(): Array<any> {
    const router = this.$route as any
    return router.matched.filter((item: RouteRecord) => item.meta && item.meta.routerName)
  }

  private get level3Menus(): Array<any> {
    if (this.routes.length >= 3) {
      if (this.routes[2].meta.showBack) {
        return []
      } else {
        return this.subRoutes
          .filter(route => route.name === this.routes[0].name)[0]
          .children.filter(child => child.name === this.routes[1].name)[0].children
      }
    } else {
      return []
    }
  }

  private selectLevel3({ key }: { key: string }): void {
    this.$router.push({ name: key }).catch(err => {})
  }
}

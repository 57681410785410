





















































































































import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import {
  setLocale,
  removeToken,
  removeSchoolInfo,
  getSchoolInfo,
  getLocale,
  setUserAuth,
  setIntroShow,
  getIntroShow,
  getGuideViewStatus,
} from '@/utils/utils'
import { RouteRecord } from 'vue-router'
import {
  LoginController,
  StudentController,
  MessageController,
  HomeworkController,
  SemesterController,
  TaskController,
  DropDownController,
} from '@/services/request.service'
import PCMenu from '@/components/PCMenu.vue'
import PadMenu from '@/components/PadMenu.vue'
import MenuLevel3 from '@/components/MenuLevel3.vue'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import debounce from 'lodash/debounce'
import Introduction from '@/components/Introduction.vue'
import { versionUpdateTip } from '@/components/version'
import Guide from '@/components/Guide.vue'
import { staticData, differSchoolId } from '@/constant/config'
import SystemUpdate from '@/components/SystemUpdate.vue'

@Component({
  components: {
    PCMenu,
    PadMenu,
    MenuLevel3,
    Introduction,
    Guide,
  },
})
export default class Main extends Vue {
  @Inject() private readonly reloadPage!: any
  private schoolVisible = false
  private selectedSchool: any = {
    schoolId: 0,
    name: '',
  }
  private sMessageNum = 0
  private mail = staticData.feedbackMail || ''
  private feedBackVis = false
  private resizeEvent: any = null
  private schoolId: any = 2
  private introVis: any = false
  private guideVis: any = false
  private guideType: any = ''
  private newMesInterval: any = 5
  mesInfo: any

  private unReadTeach: boolean = false

  private get locale(): string {
    return this.$store.state.locale
  }

  private get userName(): any {
    const { isTeacher, username, teacherEnName, teacherName } = this.$store.state.userInfo
    const realTeacherName =
      this.locale === 'zh' ? teacherName || teacherEnName : teacherEnName || teacherName
    return isTeacher ? `${realTeacherName}-${username}` : username
  }

  private getSystemMessage(): void {
    MessageController.hasUnreadSystem()
      .then(res => {
        let systemMessage = {
          cca: res.data,
        }
        let count = 0
        for (let k in systemMessage) {
          count += systemMessage[k]
        }
        this.$store.commit('setSystemMessageNum', systemMessage)
        this.sMessageNum = count
      })
      .catch(err => console.log(err))
  }

  private get contentHeaderShow(): boolean {
    const route = this.routes[this.routes.length - 1]
    if (route.meta.noChild) {
      return false
    } else {
      return true
    }
  }

  private get routes(): Array<any> {
    const router = this.$route as any
    return router.matched.filter((item: RouteRecord) => item.meta && item.meta.routerName)
  }

  private get isPad(): boolean {
    // return document.body.clientWidth < 1440
    return navigator.userAgent.indexOf('iPad') !== -1
  }

  private getMessageStatus(): void {
    MessageController.hasUnread()
      .then(res => {
        this.$store.commit('setUnreadStatus', res.data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getNewMessageNum(): void {
    MessageController.unReadMessageCount(this.newMesInterval).then(res => {
      const newMes = res.data as any
      let flag = false
      Object.keys(newMes).forEach(key => {
        if (newMes[key]) flag = true
      })
      if (flag) {
        this.$notification.info({
          message: this.$tc('message.newMessageNotice'),
          description:
            this.$t('message.receivedMessage') +
            (newMes.system
              ? '\n' + this.$tc('message.systemMessage', newMes.system, { num: newMes.system })
              : '') +
            (newMes.normal
              ? '\n' + this.$tc('message.normalMessage', newMes.normal, { num: newMes.normal })
              : ''),
          style: {
            whiteSpace: 'pre',
          },
          class: 'new-mes-info',
          duration: 30,
          onClick: () => {
            this.toInbox()
          },
        })
      }
    })
  }

  private getTeachStatus(): void {
    DropDownController.unReadTeach()
      .then(res => {
        this.unReadTeach = res.data
        this.$store.commit('setTeachStatus', this.unReadTeach)
      })
      .catch(err => console.error(err))
  }

  private checkSystemStatus(): void {
    MessageController.sysMessageNotice().then(res => {
      let { mesId, message, enMessage } = res.data
      let systemMesId = localStorage.getItem('systemMesId')
      if (mesId === null || systemMesId === mesId?.toString()) return
      if (this.mesInfo) this.mesInfo()
      let systemUpdate = this.$createElement(SystemUpdate, {
        props: {
          tips: message,
          enTips: enMessage,
        },
        on: {
          close: () => {
            localStorage.setItem('systemMesId', mesId.toString())
            this.mesInfo()
            this.mesInfo = null
          },
        },
      })
      this.mesInfo = this.$message.warning(systemUpdate, 0)
    })
  }

  private checkUnHandIn() {
    let dot = {}
    const schoolId = getSchoolInfo()?.schoolId
    if (differSchoolId.includes(schoolId)) {
      TaskController.hasUnHandIn()
        .then(res => {
          this.$store.commit('setUnfinishedStatus', res.data)
        })
        .catch(err => console.log(err))
    } else {
      HomeworkController.hasUnHandIn()
        .then(res => {
          if (res.data && res.data.length) {
            if (!res.data.length) {
              this.$store.commit('setUnfinishedStatus', false)
              this.$store.commit('setTaskDot', {})
              return
            }
            this.$store.commit('setUnfinishedStatus', true)
            res.data.includes('1041') ? this.$set(dot, 'courseClass', true) : -1
            res.data.includes('1042') ? this.$set(dot, 'CCAClass', true) : -1
            this.$store.commit('setTaskDot', dot)
          }
        })
        .catch(err => console.log(err))
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    const schoolInfo = getSchoolInfo()
    this.getMessageStatus()
    this.getSystemMessage()
    this.getTeachStatus()
    this.checkUnHandIn()
    if (schoolInfo) {
      this.selectedSchool = schoolInfo
      this.schoolId = schoolInfo.schoolId
    } else {
      this.logout()
    }
  }

  private created(): void {
    this.getUserInfo()
    this.getSchoolList()
    this.getCurrentSchoolYear()
    const locale = getLocale()

    // versionUpdateTip(this.$createElement, () => {
    const introShow = getIntroShow() || {}
    if (!introShow.pc) {
      this.introVis = true
    }
    // })

    this.$store.commit('changeLocale', locale)
  }

  private getUserInfo(): void {
    StudentController.getUserInfo()
      .then(res => {
        this.$store.commit('setUserInfo', {
          ...res.data,
          username: (res.data.enName + ' ' + res.data.name).trim(),
          memberId: res.data.studentId,
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getCurrentSchoolYear(): void {
    SemesterController.getCurrentSchoolYear()
      .then(res => {
        let currentSchoolYear = {
          ...res.data,
          startTime: moment(res.data.startTime),
          endTime: moment(res.data.endTime),
        }
        this.$store.commit('setCurrentSchoolYear', currentSchoolYear)
      })
      .catch(err => console.error(err))
  }

  private getSchoolList(): void {
    this.selectedSchool = getSchoolInfo()
  }

  private viewSystemMessage(): void {
    this.$router.push({ name: 'systemMessage' }).catch(err => {})
  }

  private openPersonal(): void {
    this.$router.push({ name: 'personal' }).catch(err => {})
  }

  private changeLocale(): void {
    const currentLocale = this.$i18n.locale
    if (currentLocale === 'zh') {
      this.$i18n.locale = 'en'
      this.$store.commit('changeLocale', 'en')
      setLocale('en')
    } else if (currentLocale === 'en') {
      this.$i18n.locale = 'zh'
      this.$store.commit('changeLocale', 'zh')
      setLocale('zh')
    }
  }

  private clickTopMenu({ key }: { key: string }): void {
    this.$router.push({ name: key }).catch(err => {})
  }

  private moveToPrevPage(): void {
    this.$router.back()
  }

  private logout(): void {
    removeToken()
    removeSchoolInfo()
    this.$store.commit('changeForceBack', true)
    this.$store.dispatch('refreshRoutes')
    this.$router.push({ name: 'login' }).catch(err => {})
  }

  private onIntroChange(vis): void {
    if (!vis) {
      let routeName = this.$route.name as any
      let matchRoute = {
        teaching: 'teaching',
        CCAClass: 'lifeBlock',
      }
      if (!Object.keys(matchRoute).includes(routeName)) return
      let status = getGuideViewStatus()
      this.guideType = matchRoute[routeName]
      if (!status[this.guideType]) {
        this.guideVis = true
      }
    }
  }

  private toHelp(): void {
    const url = staticData.helpDocUrl
    window.open(url)
  }

  private mounted() {
    this.resizeEvent = debounce(this.onResize, 100)
    let ratio = window.innerWidth / 1920
    this.$store.commit('setRatio', ratio)
    window.addEventListener('resize', this.resizeEvent)

    // 定时轮询新消息
    let newMesTimer = setInterval(() => {
      this.getNewMessageNum()
    }, this.newMesInterval * 60 * 1000)

    // 定时轮询系统推送消息
    this.checkSystemStatus()
    let checkTimer = setInterval(() => {
      this.checkSystemStatus()
      this.getTeachStatus()
    }, 60 * 60 * 1000)

    this.$once('hook:beforeDestroy', () => {
      if (this.mesInfo) this.mesInfo()
      clearInterval(newMesTimer)
      clearInterval(checkTimer)
      window.removeEventListener('resize', this.resizeEvent)
    })
  }

  private onResize(): void {
    let ratio = window.innerWidth / 1920
    this.$store.commit('setRatio', ratio)
  }

  private toInbox(): void {
    if (this.$route.matched.find(item => item.name === 'inbox')) return
    this.$router.push({ name: 'inbox', params: { mesType: 'normal' } })
  }
}
